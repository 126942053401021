import React from 'react';

const DeleteIcon = () => {
  return (
    <svg
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="21.4854"
        cy="21"
        r="20.5"
        fill="#0E2A5A"
        stroke="currentColor"
      />
      <path
        d="M25.3289 17.001H17.2548C16.83 17.001 16.4856 17.3454 16.4856 17.7699C16.4856 18.1488 16.76 18.4637 17.1206 18.5271V27.0029C17.1206 27.3693 17.4175 27.6663 17.784 27.6663H24.7997C25.1661 27.6663 25.463 27.3692 25.463 27.0029V18.5271C25.8237 18.4637 26.098 18.1488 26.098 17.7699C26.0981 17.3454 25.7536 17.001 25.3289 17.001ZM20.2938 24.8831C20.2938 25.3323 19.9294 25.6965 19.4802 25.6965C19.031 25.6965 18.6669 25.3323 18.6669 24.8831V19.7959C18.6669 19.3467 19.031 18.9826 19.4802 18.9826C19.9294 18.9826 20.2938 19.3467 20.2938 19.7959V24.8831V24.8831ZM23.9168 24.8831C23.9168 25.3323 23.5527 25.6965 23.1035 25.6965C22.6543 25.6965 22.2899 25.3323 22.2899 24.8831V19.7959C22.2899 19.3467 22.6543 18.9826 23.1035 18.9826C23.5527 18.9826 23.9168 19.3467 23.9168 19.7959V24.8831V24.8831Z"
        fill="#F3F8FE"
      />
      <path
        d="M17.2548 16.5871H25.3289C25.7536 16.5871 26.0981 16.2427 26.0981 15.8182C26.0981 15.3935 25.7536 15.0491 25.3289 15.0491H23.7781C23.7508 14.6491 23.418 14.3333 23.0113 14.3333H19.5725C19.1657 14.3333 18.8329 14.6491 18.8055 15.0491H17.2548C16.83 15.0491 16.4856 15.3935 16.4856 15.8183C16.4856 16.2428 16.83 16.5871 17.2548 16.5871Z"
        fill="#F3F8FE"
      />
    </svg>
  );
};

export default DeleteIcon;
