import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames';
import { Datepicker } from '@mobiscroll/react';

import { eventClock } from '../../../constants/icons';
import { useStyles } from './styles';
import AdvancedSetting from '../AdvancedSetting/AdvancedSetting';
import './datePicker.css';
import Time from './Time';
import Date from './Date';

const EventTime = ({
  isAllDayEvent,
  toggleAllDayEvent,
  onChangeEventTime,
  eventTime,
  eventDate,
  onChangeEventDate,
}) => {
  const [startDateRef, setStartDateRef] = useState(null);
  const [endDateRef, setEndDateRef] = useState(null);
  const [startTimeRef, setStartTimeRef] = useState(null);
  const [endTimeRef, setEndTimeRef] = useState(null);
  const [isShowStartDateCalendar, setShowStartDateCalendar] = useState(false);
  const [isShowEndDateCalendar, setShowEndDateCalendar] = useState(false);
  const [isShowStartTimeCalendar, setShowStartTimeCalendar] = useState(false);
  const [isShowEndTimeCalendar, setShowEndTimeCalendar] = useState(false);

  const styles = useStyles();

  const toggleShowStartDateCalendar = () => {
    setShowStartDateCalendar(!isShowStartDateCalendar);
  };

  const toggleShowEndDateCalendar = () => {
    setShowEndDateCalendar(!isShowEndDateCalendar);
  };

  const toggleShowStartTimeCalendar = () => {
    setShowStartTimeCalendar(!isShowStartTimeCalendar);
  };

  const toggleShowEndTimeCalendar = () => {
    setShowEndTimeCalendar(!isShowEndTimeCalendar);
  };

  const renderDash = () => (
    <Typography className={cx(styles.timeDash, styles.dateAndTimeFont)}>
      -
    </Typography>
  );

  return (
    <div className={styles.container} data-testid="eventTimeContainer">
      <div className={styles.root}>
        <div className={styles.dateContainer}>
          <img className={styles.clockIcon} src={eventClock} alt="clock" />
          <Date
            ref={setStartDateRef}
            onClick={toggleShowStartDateCalendar}
            date={eventDate?.[0]}
          />
          {isAllDayEvent && (
            <>
              {renderDash()}
              <Date
                ref={setEndDateRef}
                onClick={toggleShowEndDateCalendar}
                date={eventDate?.[1]}
              />
            </>
          )}
        </div>

        <div className={cx(styles.flexContainer)}>
          {!isAllDayEvent && (
            <>
              <Time
                ref={setStartTimeRef}
                onClick={toggleShowStartTimeCalendar}
                date={eventTime?.[0]}
                dataTestId="startTimeContainer"
              />
              {renderDash()}
              <Time
                ref={setEndTimeRef}
                onClick={toggleShowEndTimeCalendar}
                date={eventTime?.[1]}
                dataTestId="endTimeContainer"
              />
            </>
          )}
        </div>
      </div>

      <AdvancedSetting
        isAllDayEvent={isAllDayEvent}
        toggleAllDayEvent={toggleAllDayEvent}
        eventTime={eventTime}
        eventDate={eventDate}
      />

      {/* FOR SELECT START DATE */}
      <Datepicker
        controls={['calendar']}
        display="anchored"
        anchor={startDateRef}
        touchUi={false}
        isOpen={isShowStartDateCalendar}
        onClose={toggleShowStartDateCalendar}
        showInput={false}
        value={eventDate?.[0]}
        onChange={(args) => onChangeEventDate(args, 'start')}
      />

      {/* FOR SELECT END DATE */}
      <Datepicker
        controls={['calendar']}
        display="anchored"
        anchor={endDateRef}
        touchUi={false}
        isOpen={isShowEndDateCalendar}
        onClose={toggleShowEndDateCalendar}
        showInput={false}
        value={eventDate?.[1]}
        onChange={(args) => onChangeEventDate(args, 'end')}
      />

      {/* FOR SELECT START TIME */}
      <Datepicker
        controls={['time']}
        timeFormat="HH:mm"
        display="anchored"
        anchor={startTimeRef}
        touchUi={false}
        isOpen={isShowStartTimeCalendar}
        onClose={toggleShowStartTimeCalendar}
        showInput={false}
        value={eventTime?.[0]}
        onChange={(args) => onChangeEventTime(args, 'start')}
      />

      {/* FOR SELECT END TIME */}
      <Datepicker
        controls={['time']}
        timeFormat="HH:mm"
        display="anchored"
        anchor={endTimeRef}
        touchUi={false}
        isOpen={isShowEndTimeCalendar}
        onClose={toggleShowEndTimeCalendar}
        showInput={false}
        value={eventTime?.[1]}
        onChange={(args) => onChangeEventTime(args, 'end')}
        min={eventTime?.[0]?.getTime()}
      />
    </div>
  );
};

EventTime.propTypes = {
  isAllDayEvent: PropTypes.bool.isRequired,
  toggleAllDayEvent: PropTypes.func.isRequired,
  onChangeEventTime: PropTypes.func.isRequired,
  eventTime: PropTypes.array.isRequired,
  eventDate: PropTypes.array.isRequired,
  onChangeEventDate: PropTypes.func.isRequired,
};

export default EventTime;
