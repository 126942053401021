import React from 'react';
import PropTypes from 'prop-types';

const ArrowIcon = ({ isShowDateCalendar }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="7.5"
        fill="white"
        stroke="#E0E8F7"
      />
      <path
        transform={isShowDateCalendar && 'rotate(-180) translate(-24, -24)'}
        d="M12.0017 13.3198L9.00878 10.1359C8.92657 10.0482 8.81665 10 8.69945 10C8.58218 10 8.47233 10.0482 8.38998 10.1359L8.12787 10.4149C8.0454 10.5024 8 10.6194 8 10.7441C8 10.8688 8.0454 10.9857 8.12787 11.0733L11.6912 14.8642C11.7738 14.9522 11.8842 15.0003 12.0015 15C12.1193 15.0003 12.2296 14.9523 12.3123 14.8642L15.8721 11.0768C15.9546 10.9892 16 10.8723 16 10.7476C16 10.6229 15.9546 10.506 15.8721 10.4184L15.61 10.1394C15.4394 9.95793 15.1617 9.95793 14.9912 10.1394L12.0017 13.3198Z"
        fill="#556E9A"
      />
    </svg>
  );
};
ArrowIcon.propTypes = {
  isShowDateCalendar: PropTypes.bool,
};

export default ArrowIcon;
