/* eslint-disable import/prefer-default-export */
export const BASE_API_URL = '';
export const GATEWAY_API_URL =
  'https://api-gateway-mock-rq234cdqga-ew.a.run.app/core/gateway/v1';

export const EMPLOYEE_API_URL =
  'https://be-employee-management-rq234cdqga-ew.a.run.app/core/employee-management/api/v1/';

export const CALENDAR_API_URL =
  'https://be-calendar-rq234cdqga-ew.a.run.app/core/calendar/api/v1';

export const PATIENT_API_URL =
  'https://be-patient-management-rq234cdqga-ew.a.run.app/core/patient-management/api/v1';

export const FAUNADB = {
  DOMAIN: 'db.eu.fauna.com',
  GRAPHQL_URL: 'https://graphql.eu.fauna.com/graphql',
};
