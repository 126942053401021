import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from '@dechea/orc.services.library';

import styles from '../../patientDropdown.module.scss';
import DefaultPatientIcon from '../../../../assets/icons/AvatarIcon';
import PatientAvatar from '../../../PatientAvatar';
import ArrowIcon from '../../../../assets/icons/ArrowIcon';

const DropdownHeader = ({
  isMenuOpen,
  isError,
  setIsMenuOpen,
  selectedPatient,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx(styles.root, {
        [styles.dropMenuActive]: isMenuOpen,
        [styles.error]: isError,
      })}
      onClick={() => {
        setIsMenuOpen(!isMenuOpen);
      }}
    >
      <div
        className={cx(
          { [styles.dropMenu]: !isMenuOpen },
          { [styles.dropMenu]: isMenuOpen }
        )}
      >
        <div
          className={styles.addPatientContainer}
          data-testid="selectPatientContainer"
        >
          {selectedPatient === null ? (
            <div className={styles.placeHolderContainer}>
              <DefaultPatientIcon />
              <div className={styles.placeHolderText}>
                {t('calendar.event.selectPatient')}
              </div>
            </div>
          ) : (
            <div className={styles.valueContainer}>
              <PatientAvatar
                patient={selectedPatient.value}
                className={styles.defImage}
              />
              <div className={styles.valueLabel}>{selectedPatient.label}</div>
            </div>
          )}
        </div>
        <div className={styles.placeHolderContainer}>
          <div>
            <div className={styles.actionContainer}>
              <ArrowIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DropdownHeader.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  setIsMenuOpen: PropTypes.func.isRequired,
  selectedPatient: PropTypes.object.isRequired,
};

export default DropdownHeader;
