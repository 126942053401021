import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from '@dechea/orc.services.library';

import DefaultImage from '../../../DefaultImage';
import ArrowIcon from '../../../../assets/icons/ArrowIcon';

const DropdownHeader = ({
  toggleCalendarVisiblity,
  isDropdownVisible,
  isError,
  selectedOptionsList,
  isAllOptionsSelected,
}) => {
  const { t } = useTranslation();
  const selectedOptionsListLength = selectedOptionsList?.length;

  return (
    <div
      id="dropdown-input"
      onClick={toggleCalendarVisiblity}
      className={cx('dropdown_input_container', {
        dropdown_input_container_enabled: isDropdownVisible,
        validationError: isError,
      })}
    >
      <div className="displayFlex" style={{ justifyContent: 'space-between' }}>
        <div
          className={cx('displayFlex', {
            'selected-emp': selectedOptionsListLength,
          })}
        >
          <div className="img_container">
            {selectedOptionsListLength !== 1 && (
              <div
                className={cx('total_team_text', {
                  'total_team_text-active': selectedOptionsListLength > 1,
                })}
              >
                <span>{selectedOptionsListLength}</span>
              </div>
            )}

            {selectedOptionsListLength > 0 &&
              (selectedOptionsList[0].avatar === null ||
              selectedOptionsList[0].avatar === undefined ? (
                <DefaultImage
                  className={cx('default-image-group-1', {
                    'single-default-image-group-1':
                      selectedOptionsListLength === 1,
                  })}
                />
              ) : (
                <img src={selectedOptionsList[0].avatar} alt="" />
              ))}

            {selectedOptionsListLength > 2 &&
              (selectedOptionsList[1].avatar === null ||
              selectedOptionsList[1].avatar === undefined ? (
                <DefaultImage className="default-image-group-2" />
              ) : (
                <img src={selectedOptionsList[1].avatar} alt="" />
              ))}
          </div>
          <div className="middle_section" data-testid="practitionersContainer">
            {isAllOptionsSelected || Boolean(!selectedOptionsListLength) ? (
              <span>{t('calendar.event.practitioners')}</span>
            ) : (
              <span>
                {selectedOptionsList
                  ?.map((selectedOption) => selectedOption?.displayName)
                  .join(', ')}
              </span>
            )}
          </div>
        </div>
        <div className="right_section">
          <div className="action-container">
            <ArrowIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

DropdownHeader.propTypes = {
  toggleCalendarVisiblity: PropTypes.func.isRequired,
  isDropdownVisible: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  selectedOptionsList: PropTypes.array.isRequired,
  isAllOptionsSelected: PropTypes.bool.isRequired,
};

export default DropdownHeader;
