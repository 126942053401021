import colors from '../colors';

const MuiTabs = {
  indicator: {
    height: 3,
    borderRadius: '8px 8px 0 0',
    backgroundColor: colors.marinerBlue,
  },
};

export default MuiTabs;
