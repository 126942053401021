import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 24,
    padding: 0,
    paddingTop: 4,
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0 24px',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pointerContainer: {
    cursor: 'pointer',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
  },
  dateAndTimeFont: {
    minWidth: 'fit-content',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '140%',
    color: theme.palette.selagoBlue,
  },
  timeDash: {
    margin: 'auto 4px',
    padding: 'auto',
  },
  hoveredDateAndTime: {
    padding: '4px 6px',

    '&:hover': {
      background: theme.palette.biscay,
      borderRadius: 8,
    },
  },
  clockIcon: {
    marginRight: 4,
  },
}));
