import { alpha } from '@material-ui/core';
import colors from '../colors';

const MuiAutocomplete = {
  root: {
    backgroundColor: alpha(colors.rhinoBlue, 0.3),
  },
};

export default MuiAutocomplete;
