import React from 'react';
import PropTypes from 'prop-types';
import { Popup as MbscPopup } from '@mobiscroll/react';

import './popup.css';

const Popup = ({ children, width, ...props }) => {
  const responsivePopup = {
    medium: {
      display: 'anchored',
      width,
      fullScreen: false,
      touchUi: false,
    },
  };

  return (
    <MbscPopup
      className="eventPopup"
      display="bottom"
      fullScreen={true}
      contentPadding={false}
      responsive={responsivePopup}
      scrollLock={false}
      {...props}
    >
      {children}
    </MbscPopup>
  );
};

Popup.propTypes = {
  children: PropTypes.element.isRequired,
  width: PropTypes.number.isRequired,
};

Popup.defaultProps = {
  width: 400,
};

export default Popup;
