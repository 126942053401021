import colors from '../colors';

const MuiOutlinedInput = {
  root: {
    '&:hover $notchedOutline': {
      borderColor: colors.paleNavyBlue,
    },
    '&$focused $notchedOutline': {
      borderColor: colors.marinerBlue,
      borderWidth: 1,
    },
  },
  focused: {},
  notchedOutline: {
    borderRadius: 8,
    borderColor: colors.lightSolitudeGray,
  },
};

export default MuiOutlinedInput;
