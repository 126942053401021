import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import DefaultImage from '../../../DefaultImage';
import GridContainer from '../../../Mui/GridContainer/GridContainer';
import { useStyles } from './styles';
import { getPatientName } from '../../../../utils/getPatientName';

const PractitionerList = ({ practitioner }) => {
  const styles = useStyles();

  const empImage =
    !practitioner?.image?.archived && practitioner?.image?.encodedImage
      ? atob(practitioner?.image?.encodedImage)
      : null;

  return (
    <GridContainer
      className={styles.root}
      alignItems="center"
      wrap="nowrap"
      data-testid="practitionerListOverviewContainer"
    >
      {empImage ? (
        <img
          src={empImage}
          alt=""
          className={styles.img}
          data-testid="empImg"
        />
      ) : (
        <DefaultImage className={styles.img} dataTestId="defaultImg" />
      )}

      <Typography
        className={styles.practitionerName}
        data-testid="practitionerName"
      >
        {getPatientName(practitioner)}
      </Typography>
    </GridContainer>
  );
};

PractitionerList.propTypes = {
  practitioner: PropTypes.object,
};

export default PractitionerList;
