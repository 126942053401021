import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '../../assets/icons/DeleteIcon';
import { useStyles } from './styles';

const EventOverviewFooter = ({
  onClickEditEventButton,
  onClickDeleteEventButton,
  event,
}) => {
  const styles = useStyles();

  return (
    <div
      className={styles.container}
      data-testid="eventOverviewfooterContainer"
    >
      <IconButton
        data-testid="eventOverviewDeleteButton"
        className={styles.deleteIconContainer}
        onClick={() => onClickDeleteEventButton(event?.id)}
      >
        <DeleteIcon />
      </IconButton>
      {/* <Button
        className={styles.createButton}
        classes={{ label: styles.createButtonLabel }}
        data-testid="eventOverviewEditButton"
        onClick={onClickEditEventButton}
      >
        {t('common.edit')}
      </Button> */}
    </div>
  );
};

EventOverviewFooter.propTypes = {
  eventId: PropTypes.number,
  onClickDeleteEventButton: PropTypes.func,
  onClickEditEventButton: PropTypes.func,
};

export default EventOverviewFooter;
