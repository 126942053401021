import React from 'react';

import EventLabel from '../EventLabel/EventLabel';
import { useStyles } from './styles';

const MoreEventsPopup = (props) => {
  const styles = useStyles({
    isMultiDay: props.isMultiDay,
    ...props?.original?.color?.inActive,
  });

  return (
    <div
      data-testid="moreEventsPopupContainer"
      className={styles.root}
      title={props?.title}
    >
      <EventLabel {...props} />
    </div>
  );
};

export default MoreEventsPopup;
