import React from 'react';

const SearchIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8494 4.67715C10.7434 2.57116 7.31576 2.57116 5.20977 4.67715C3.10423 6.7836 3.10423 10.2108 5.20977 12.3173C7.08521 14.1922 10.0061 14.3931 12.1107 12.929C12.155 13.1386 12.2564 13.3385 12.4194 13.5015L15.4864 16.5685C15.9333 17.0145 16.6555 17.0145 17.1002 16.5685C17.5467 16.122 17.5467 15.3998 17.1002 14.9546L14.0332 11.8867C13.8711 11.7251 13.6707 11.6233 13.4612 11.579C14.9262 9.47396 14.7253 6.55351 12.8494 4.67715ZM11.8811 11.3489C10.3088 12.9212 7.74992 12.9212 6.17808 11.3489C4.60669 9.77664 4.60669 7.21822 6.17808 5.64592C7.74992 4.07407 10.3088 4.07407 11.8811 5.64592C13.4534 7.21822 13.4534 9.77664 11.8811 11.3489Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SearchIcon;
