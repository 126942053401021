import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import cx from 'classnames';
import { useTranslation } from '@dechea/orc.services.library';

import GridContainer from '../../Mui/GridContainer/GridContainer';
import { useStyles } from './styles';

const EventTypes = ({
  eventTypes,
  selectedEventTypes,
  onSelectEventType,
  isError,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const isSelectedEventType = (id) =>
    selectedEventTypes.some(
      (selectedEventType) => selectedEventType?.id === id
    );

  return (
    <div className={styles.container}>
      <Typography
        className={cx(styles.title, { [styles.validationError]: isError })}
        data-testid="eventTypeContainer"
      >
        {`${t('calendar.event.type')}:`}
      </Typography>
      <div>
        {eventTypes?.length ? (
          <GridContainer>
            {eventTypes?.map((eventType) => (
              <Chip
                data-testid={eventType?.id}
                className={cx(styles.root, {
                  [styles.activeChip]: isSelectedEventType(eventType.id),
                })}
                classes={{ label: styles.label }}
                label={t(`calendar.eventType.${eventType?.titleCode}`)}
                clickable
                onClick={() => onSelectEventType(eventType)}
              />
            ))}

            {/* UNCOMMENT FOR SHOW MORE BUTTON */}
            {/* <Chip
          className={styles.root}
          clickable
          classes={{
            label: cx(styles.label, styles.labelPaddingLeft),
            deletable: styles.deleteIcon,
          }}
          label={t('calendar.event.more')}
          deleteIcon={<MoreDots />}
          onDelete={() => {}}
        /> */}
          </GridContainer>
        ) : (
          <GridContainer
            justifyContent="center"
            alignItems="center"
            className={styles.typeContainer}
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress size={30} className={styles.loader} />
              <Typography>Loading...</Typography>
            </Box>
          </GridContainer>
        )}
      </div>
    </div>
  );
};

EventTypes.propTypes = {
  eventTypes: PropTypes.array.isRequired,
  selectedEventTypes: PropTypes.array.isRequired,
  onSelectEventType: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
};

export default EventTypes;
