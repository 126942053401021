import React from 'react';
import PropTypes from 'prop-types';

import GridContainer from '../../../Mui/GridContainer/GridContainer';
import EventTypes from '../EventTypes/EventTypes';
import PractitionerWrapper from '../PractitionerWrapper/PractitionerWrapper';
import { useStyles } from './styles';

const Body = ({ event }) => {
  const styles = useStyles();

  return (
    <GridContainer
      direction="column"
      className={styles.root}
      data-testid="eventOverviewBody"
    >
      <PractitionerWrapper practitioners={event?.employeeEntity} />
      <EventTypes eventTypes={event?.eventTypes} />
    </GridContainer>
  );
};

Body.propTypes = {
  event: PropTypes.object,
};

export default Body;
