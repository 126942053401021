import React from 'react';
import { components } from 'react-select';

import PatientAvatar from '../../../PatientAvatar';
import styles from '../../patientDropdown.module.scss';
import formatDate from '../../../../utils/formatDate';

const { Option } = components;

const PatientList = (props) => {
  const birthDate = `${formatDate(
    props?.data?.value?.birthdate?.value
  )} (${Math.floor(
    (new Date() - new Date(props?.data?.value?.birthdate?.value)) /
      3600000 /
      24 /
      365
  )})`;

  return (
    <Option {...props}>
      <div
        data-testid={`patientList-${props.data.label}`}
        className={styles.profileContainer}
      >
        <div className={styles.optionWrapper}>
          <PatientAvatar
            patient={props?.data?.value}
            className={styles.defImage}
          />
          <div className={styles.label}>{props.data.label}</div>
        </div>
        <div className={styles.birthDate}>{birthDate}</div>
      </div>
    </Option>
  );
};

export default PatientList;
