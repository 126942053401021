import { createTheme } from '@material-ui/core/styles';
import { breakPointsValues } from './breakpoints';
import { h2 } from './typography/h2';
import { h4 } from './typography/h4';
import { h5 } from './typography/h5';
import { subtitle1 } from './typography/subtitle1';
import { subtitle2 } from './typography/subtitle2';
import { body1 } from './typography/body1';
import { body2 } from './typography/body2';
import { caption } from './typography/caption';
import { button } from './typography/button';
import MuiBackdrop from './components/MuiBackdrop';
import MuiTextField from './components/MuiTextField';
import MuiInput from './components/MuiInput';
import MuiInputBase from './components/MuiInputBase';
import MuiOutlinedInput from './components/MuiOutlinedInput';
import MuiCheckbox from './components/MuiCheckbox';
import MuiButton from './components/MuiButton';
import MuiIconButton from './components/MuiIconButton';
import MuiIcon from './components/MuiIcon';
import MuiAutocomplete from './components/MuiAutocomplete';
import MuiFormControl from './components/MuiFormControl';
import MuiGrid from './components/MuiGrid';
import MuiRadio from './components/MuiRadio';
import MuiFormLabel from './components/MuiFormLabel';
import MuiInputLabel from './components/MuiInputLabel';
import MuiDialog from './components/MuiDialog';
import MuiDialogContent from './components/MuiDialogContent';
import MuiTab from './components/MuiTab';
import MuiTabs from './components/MuiTabs';

import colors from './colors';

const {
  aliceBlue,
  weldonBlue,
  marinerBlue,
  cyanBlue,
  waikawaGray,
  paleNavyBlue,
  gulfBlue,
  rockBlue,
  catalinaBlue,
  royalBlue,
  royalBlueVariant,
  spindleGray,
  solitudeGray,
  lightSolitudeGray,
  darkCatalinaBlue,
  shipCoveGray,
  midnightExpressBlue,
  sapphireBlue,
  redOrange,
  amaranthRed,
  whiteLilac,
  hawkesBlue,
  pantoneBlue,
  cabaretRed,
  lavender,
  tumbleweedBrown,
  watusiOrange,
  feldsparRed,
  selagoBlue,
  rhinoBlue,
  portGoreBlue,
  shipCoveBlue,
  poloBlue,
  zircon,
  astronautBlue,
  glaucous,
  cornHarvest,
  earlyDawn,
  lavenderBlush,
  wedgewood,
  periwinkleGray,
  deepCove,
  biscay,
  linkWater,
  stTropaz,
  cornflowerBlue,
  downriver,
  blueZodiac,
  wildWatermelon,
  shipCove,
  bayOfMany,
  whitePointer1,
  steelBlue,
  pigeonPost,
} = colors;

export const muiTheme = createTheme({
  breakpoints: {
    values: breakPointsValues,
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: 'Montserrat,sans-serif !important',
      },
      h2,
      h4,
      h5,
      subtitle1,
      subtitle2,
      body1,
      body2,
      caption,
      button,
    },
    MuiBackdrop,
    MuiGrid,
    MuiFormControl,
    MuiInput,
    MuiInputBase,
    MuiOutlinedInput,
    MuiCheckbox,
    MuiTextField,
    MuiButton,
    MuiIconButton,
    MuiIcon,
    MuiAutocomplete,
    MuiRadio,
    MuiFormLabel,
    MuiInputLabel,
    MuiDialog,
    MuiDialogContent,
    MuiTab,
    MuiTabs,
  },
  palette: {
    primary: {
      main: gulfBlue,
      light: cyanBlue,
    },
    weldonBlue,
    marinerBlue,
    aliceBlue,
    waikawaGray,
    paleNavyBlue,
    rockBlue,
    catalinaBlue,
    royalBlue,
    royalBlueVariant,
    spindleGray,
    solitudeGray,
    lightSolitudeGray,
    darkCatalinaBlue,
    shipCoveGray,
    midnightExpressBlue,
    sapphireBlue,
    redOrange,
    amaranthRed,
    whiteLilac,
    hawkesBlue,
    pantoneBlue,
    cabaretRed,
    lavender,
    tumbleweedBrown,
    watusiOrange,
    feldsparRed,
    selagoBlue,
    rhinoBlue,
    portGoreBlue,
    shipCoveBlue,
    poloBlue,
    zircon,
    astronautBlue,
    glaucous,
    cornHarvest,
    earlyDawn,
    lavenderBlush,
    wedgewood,
    periwinkleGray,
    deepCove,
    biscay,
    linkWater,
    stTropaz,
    cornflowerBlue,
    downriver,
    blueZodiac,
    wildWatermelon,
    shipCove,
    bayOfMany,
    whitePointer1,
    steelBlue,
    pigeonPost,
  },
});
