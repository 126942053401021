import moment from 'moment';
import { DAY_VIEW, MONTH_VIEW, WEEK_VIEW } from '../constants/views';

export const getCalendarActiveView = (viewMode) => {
  const view = {
    viewMode: viewMode || MONTH_VIEW,
    calView: { calendar: { labels: true } },
    viewStartDate: moment().startOf(viewMode),
  };

  switch (viewMode) {
    case MONTH_VIEW:
      view.calView = {
        calendar: { labels: true, popoverClass: 'showMoreEventsPopover' },
      };
      break;
    case WEEK_VIEW:
      view.calView = {
        schedule: { type: WEEK_VIEW },
      };
      break;
    case DAY_VIEW:
      view.calView = {
        schedule: { type: DAY_VIEW },
      };
      break;

    default:
      return view;
  }

  return view;
};
