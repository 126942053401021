import * as React from 'react';
import PropTypes from 'prop-types';
import { reducer } from './rootReducer';
import combineReducer from './combinerReducers';
import useReducerWithThunk from './useReducerWithThunk';

// eslint-disable-next-line import/no-mutable-exports
let contextValue = null;

const AppStateProvider = React.createContext({});

const ContextProvider = ({ children }) => {
  const reducers = React.useCallback(() => {
    return combineReducer(reducer);
  }, []);

  // call the function to get initial state and global reducer
  const [initialState, mainReducer] = reducers();

  // setup useReducer with the returned value of the reducers function
  const [state, dispatch] = useReducerWithThunk(mainReducer, initialState);

  // pass in the returned value of useReducer
  contextValue = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <AppStateProvider.Provider value={contextValue}>
      {children}
    </AppStateProvider.Provider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.node,
};

export { ContextProvider, AppStateProvider as ContextConsumer, contextValue };
