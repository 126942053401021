import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@dechea/orc.services.library';
import cx from 'classnames';

import SearchIcon from '../../../../assets/icons/SearchIcon';

const Search = ({ onSearch, searchTerm, onClearInputText }) => {
  const [isFocus, setFocus] = useState(false);
  const { t } = useTranslation();

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  return (
    <div
      id="search-box"
      className="search_container"
      data-testid="searchContainer"
    >
      <div
        id="search-icon"
        className={cx('search_icon', {
          'search_icon-inactive': isFocus,
        })}
      >
        <SearchIcon />
      </div>
      <input
        data-testid="searchInputField"
        placeholder={`${t('calendar.event.practitioner')}...`}
        value={searchTerm}
        onChange={onSearch}
        className="search_container_focus"
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
};

Search.propTypes = {
  onSearch: PropTypes.func,
  searchTerm: PropTypes.string,
  onClearInputText: PropTypes.func.isRequired,
};

export default Search;
