import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { useTranslation } from '@dechea/orc.services.library';

import { useStyles } from './styles';
import { DAY_VIEW, MONTH_VIEW, WEEK_VIEW } from '../../constants/views';
import { getConcatedEventTypes } from '../../utils/getFormattedEventTypes';
import { getPatientName } from '../../utils/getPatientName';

const ScheduleEvent = ({ view, activeEventId, ...props }) => {
  const colors = {
    color:
      activeEventId === props?.id
        ? props?.original?.color?.active
        : props?.original?.color?.inActive,
  };

  const styles = useStyles({
    ...colors?.color,
  });
  const { t } = useTranslation();
  const isCreatingEvent = props?.id?.toString()?.includes?.('mbsc');

  const renderEventTime = () => (
    <div
      data-testid="eventSchedulerTime"
      className={cx(styles.headingFont, styles.time)}
    >
      {moment(props?.original?.start).format('HH:mm')}
      {(view === DAY_VIEW || isCreatingEvent) &&
        ` – ${moment(props?.original?.end).format('HH:mm')}`}
    </div>
  );

  const renderEventTitle = (isNewEvent) => (
    <div
      data-testid="eventSchedulerTitle"
      className={cx(styles.headingFont, styles.ellipsisFont)}
    >
      {isNewEvent
        ? t('calendar.newEvent')
        : getPatientName(props?.original?.patientEntity)}
    </div>
  );

  const renderNewEvent = () => (
    <div data-testid="newEventScheduler" className={styles.newEventBody}>
      {renderEventTime()}
      {renderEventTitle(true)}
    </div>
  );

  const renderCreatedEvent = () => (
    <>
      <div data-testid="eventSchedulerTimeContainer" className={styles.heading}>
        {renderEventTime()}
        <div data-testid="eventSchedulerDot" className={styles.sepratorDot} />
        {renderEventTitle()}
      </div>

      <div
        data-testid="eventSchedulerTypesContainer"
        className={styles.eventTypeContainer}
      >
        <div
          data-testid="eventSchedulerTypes"
          className={cx(styles.eventTypesFont, styles.ellipsisFont)}
        >
          {getConcatedEventTypes(props?.original?.eventTypes)}
        </div>
      </div>
    </>
  );

  return (
    <>
      <div
        data-testid="schedulerEventContainer"
        className={cx(styles.scheduleEventWrapper, {
          [styles.newEventContainer]: isCreatingEvent,
        })}
      >
        <div
          data-testid="schedulerSidebar"
          className={styles.scheduleEventSidebar}
        />
        <div data-testid="eventSchedulerBody" className={styles.bodyContainer}>
          {isCreatingEvent ? renderNewEvent() : renderCreatedEvent()}
        </div>
      </div>
    </>
  );
};

ScheduleEvent.propTypes = {
  view: PropTypes.oneOf([DAY_VIEW, WEEK_VIEW, MONTH_VIEW]),
  activeEventId: PropTypes.number,
};

export default ScheduleEvent;
