import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: '4px 0 12px',
  },
  eventTypeWrapper: {
    padding: '6px 12px',
    background: theme.palette.biscay,
    borderRadius: 8,
    margin: '4px 8px 4px 0',
  },
  eventTypeLabel: {
    fontSize: 12,
    lineHeight: '135%',
    fontWeight: 500,
    color: theme.palette.whitePointer1,
  },
}));
