const MuiAutocomplete = {
  paper: {
    backgroundColor: '#142A51',
  },
  listbox: {
    padding: 0,
  },
  option: {
    height: 48,
    color: '#FFFFFF',
    lineHeight: '19.6px',
    borderBottom: '1px solid #25406D',
  },
  input: {
    textTransform: 'capitalize',
  },
  noOptions: {
    height: 48,
    color: '#FFFFFF',
  },
};

export default MuiAutocomplete;
