import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CalendarService from '../../api/calendar';
import { getFormattedStartAndEndDate } from '../../utils/events';
import {
  eventFormValidation,
  isValidEventForm,
} from '../Event/eventFormValidation';
import PatientEventForm from '../PatientEventForm/PatientEventForm';
import { callLambdaFunction } from '../../utils/faunadb/callLambdaFunction/callLambdaFunction';
import { GET_PATIENTS } from '../../constants/faunadb/functions';
import { getPatientManipulatedData } from '../../utils/manipulatePatientData/getManipulatedPatientData';

const now = new Date();
const DEFAULT_PAGE_SIZE = 15;

const PatientEvent = ({
  isOpen,
  tempEvent,
  saveEvent,
  isEdit,
  onClickDeleteEventButton,
}) => {
  const [isAllDayEvent, setAllDayEvent] = useState(true);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const [eventTime, setEventTime] = useState([now, now]);
  const [eventDate, setEventDate] = useState([now, now]);
  const [errors, setErrors] = useState();
  const [hasMoreEventTypes, setHasMoreEventTypes] = useState(true);
  const [eventTypesPage, setEventTypesPage] = useState(1);
  const [eventTypes, setEventTypes] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [hasMorePatients, setHasMorePatients] = useState(true);
  const [patientListPage, setPatientListPage] = useState(1);
  const [patientsAfterCursor, setPatientsAfterCursor] = useState([]);

  const eventId = !tempEvent?.id?.toString()?.includes('mbsc')
    ? tempEvent?.id
    : null;

  // GET EVENT TYPES
  const getTreatmentTypesList = () => {
    if (hasMoreEventTypes) {
      CalendarService.getEventTypes(eventTypesPage, 10).then((result) => {
        const eventTypePage = result?.filter((eventType) => !eventType?.status);
        if (eventTypePage.length) {
          setEventTypes([...eventTypes, ...eventTypePage]);
          setEventTypesPage(eventTypesPage + 1);
        } else {
          setEventTypes(eventTypes);
          setHasMoreEventTypes(false);
        }
      });
    }
  };

  useEffect(() => {
    if (eventTypesPage !== 1) {
      getTreatmentTypesList();
    }
  }, [eventTypesPage]);

  const manipulatePatient = (faunadbPatient) => {
    return faunadbPatient?.map((patient) => getPatientManipulatedData(patient));
  };

  // GET PATIENT LIST
  const getPatientsLists = async () => {
    try {
      if (hasMorePatients) {
        const gqlPatients = await callLambdaFunction(
          GET_PATIENTS,
          true,
          DEFAULT_PAGE_SIZE,
          patientsAfterCursor
        );
        const patientsData = manipulatePatient(gqlPatients?.data);
        setPatientList([...patientList, ...patientsData]);
        if (gqlPatients?.after) {
          setHasMorePatients(true);
          setPatientListPage(patientListPage + 1);
          setPatientsAfterCursor(gqlPatients?.after);
        } else {
          setHasMorePatients(false);
          setPatientsAfterCursor([]);
        }
      }
    } catch (error) {
      setHasMorePatients(false);
    }
  };

  useEffect(() => {
    if (patientListPage !== 1) {
      getPatientsLists();
    }
  }, [patientsAfterCursor]);

  // GET EVENT TYPES AND PATIENT LIST
  useEffect(() => {
    getTreatmentTypesList();
    getPatientsLists();
  }, []);

  const validateForm = () => {
    const formData = {
      practitioner: selectedEmployee,
      patient: selectedPatient?.value?.patients?.[0]?.id,
      eventType: selectedEventTypes,
      date: {
        isAllDayEvent,
        eventTime,
        eventDate,
      },
    };
    const error = eventFormValidation(formData);
    setErrors((preError) => ({ ...preError, ...error }));
    return error;
  };

  // RESET EVENT FORM
  useEffect(() => {
    if (!isOpen) {
      resetEventForm();
    }
  }, [isOpen]);

  const resetEventForm = () => {
    setSelectedEmployee([]);
    setSelectedPatient(null);
    setSelectedEventTypes([]);
    setEventTime([now, now]);
    setEventDate([now, now]);
    setErrors();
  };

  // AUTO FILL EVENT FORM ON EVENT CLICK
  useEffect(() => {
    if (tempEvent) {
      setEventDate([tempEvent?.start, tempEvent?.end]);
      setEventTime([tempEvent?.start, tempEvent?.end]);
      setAllDayEvent(tempEvent.allDay || false);
    }
  }, [isEdit, tempEvent]);

  // TO SELECT EVENT TYPES
  const onSelectEventType = (eventType) => {
    const selectedEventTypesCopy = [...selectedEventTypes];

    const eventTypeIndex = selectedEventTypes?.findIndex(
      (selectedEventType) => selectedEventType.id === eventType?.id
    );

    if (eventTypeIndex === -1) {
      selectedEventTypesCopy.push({ id: eventType?.id });
    } else {
      selectedEventTypesCopy.splice(eventTypeIndex, 1);
    }

    setSelectedEventTypes(selectedEventTypesCopy);
  };

  // TO CHANGE EVENT START AND END TIME
  const onChangeEventTime = (args, timeType) => {
    if (timeType === 'start') {
      setEventTime([args.value, eventTime?.[1]]);
    }
    if (timeType === 'end') {
      setEventTime([eventTime?.[0], args.value]);
    }
  };

  // TO CHANGE EVENT START AND END DATE
  const onChangeEventDate = (args, timeType) => {
    if (timeType === 'start') {
      setEventDate([args.value, eventDate?.[1]]);
    }
    if (timeType === 'end') {
      setEventDate([eventDate?.[0], args.value]);
    }
  };

  const toggleAllDayEvent = useCallback((ev) => {
    setAllDayEvent(ev.target.checked);
  }, []);

  const onClickCreateEventButton = () => {
    const error = validateForm();
    const isValid = isValidEventForm(error);

    if (!isValid) {
      const { endDate, startDate } = getFormattedStartAndEndDate(
        eventDate,
        eventTime,
        isAllDayEvent
      );

      const newEvent = {
        allDay: isAllDayEvent,
        color: tempEvent?.color,

        eventType: selectedEventTypes,
        archived: false,
        canceled: false,
        title: 'This is a sample Event',
        description: 'This is a sample Event',
        startDate,
        endDate,
        employeeIds: selectedEmployee,
        patientId: selectedPatient?.value?.patients?.[0]?.id,
        roomId: 0,
      };
      saveEvent(newEvent);
    }
  };

  return (
    <PatientEventForm
      isEdit={isEdit}
      eventId={eventId}
      isAllDayEvent={isAllDayEvent}
      toggleAllDayEvent={toggleAllDayEvent}
      selectedEmployee={selectedEmployee}
      setSelectedEmployee={setSelectedEmployee}
      eventTypes={eventTypes}
      onSelectEventType={onSelectEventType}
      selectedEventTypes={selectedEventTypes}
      patientList={patientList}
      selectedPatient={selectedPatient}
      setSelectedPatient={setSelectedPatient}
      onChangeEventTime={onChangeEventTime}
      eventTime={eventTime}
      eventDate={eventDate}
      onChangeEventDate={onChangeEventDate}
      onClickCreateEventButton={onClickCreateEventButton}
      errors={errors}
      onClickDeleteEventButton={onClickDeleteEventButton}
    />
  );
};

PatientEvent.propTypes = {
  isOpen: PropTypes.bool,
  isEdit: PropTypes.bool,
  tempEvent: PropTypes.object,
  saveEvent: PropTypes.func.isRequired,
  onClickDeleteEventButton: PropTypes.func.isRequired,
};

export default PatientEvent;
