import React from 'react';
import DefaultImage from '../DefaultImage';

const PatientAvatar = ({ patient, ...props }) => {
  let avatarUrl;
  if (patient && patient.patients && patient.patients.length) {
    const avatar = patient.patients[0]?.image
      ? patient.patients[0].image
      : null;
    if (avatar) {
      avatarUrl = window.atob(avatar);
    }
  }
  if (!avatarUrl) return <DefaultImage dataTestId="defaultImage" {...props} />;

  if (avatarUrl?.length)
    return (
      <img data-testid="patientImg" src={avatarUrl} {...props} alt="avatar" />
    );

  return (
    <DefaultImage dataTestId="defaultImage" value={avatarUrl} {...props} />
  );
};

export default PatientAvatar;
