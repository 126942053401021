import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from '@dechea/orc.services.library';

import DeleteIcon from '../../../assets/icons/DeleteIcon';
import { useStyles } from './styles';

const Footer = ({
  onClickCreateEventButton,
  onClickDeleteEventButton,
  eventId,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.container} data-testid="footerContainer">
      <IconButton
        data-testid="eventDeleteButton"
        className={styles.deleteIconContainer}
        onClick={() => onClickDeleteEventButton(eventId)}
        disabled={!eventId}
      >
        <DeleteIcon />
      </IconButton>
      <Button
        className={styles.createButton}
        classes={{ label: styles.createButtonLabel }}
        data-testid="eventCreateButton"
        onClick={onClickCreateEventButton}
      >
        {t('common.create')}
      </Button>
    </div>
  );
};

Footer.propTypes = {
  eventId: PropTypes.number,
  onClickDeleteEventButton: PropTypes.func,
  onClickCreateEventButton: PropTypes.func,
};

export default Footer;
