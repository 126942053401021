import React from 'react';
import PropTypes from 'prop-types';

import Footer from '../EventOverviewFooter/EventOverviewFooter';
import GridContainer from '../Mui/GridContainer/GridContainer';
import Body from './components/Body/Body';
import Header from './components/Header/Header';

const PatientEventOverview = ({
  event,
  onClickDeleteEventButton,
  onClickEditEventButton,
}) => {
  return (
    <GridContainer
      direction="column"
      data-testid="patientEventOverviewContainer"
    >
      <Header event={event} />
      <Body event={event} />
      <Footer
        event={event}
        onClickEditEventButton={onClickEditEventButton}
        onClickDeleteEventButton={onClickDeleteEventButton}
      />
    </GridContainer>
  );
};

PatientEventOverview.propTypes = {
  event: PropTypes.object,
  onClickEditEventButton: PropTypes.func,
  onClickDeleteEventButton: PropTypes.func,
};

export default PatientEventOverview;
