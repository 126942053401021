const getAddresses = (addresses) =>
  addresses?.map((address) => ({
    ...address?.data,
    _id: address?.ref?.value?.id,
    ref: address?.ref,
  }));

const getFormattedObject = (data) => ({
  ...data?.data,
  _id: data?.ref?.value?.id,
});

export const getPatientManipulatedData = (patientData) => {
  if (!patientData) {
    return null;
  }

  const user = patientData?.user?.data
    ? getFormattedObject(patientData?.user)
    : patientData?.user;
  const patient = patientData?.patient
    ? getFormattedObject(patientData?.patient)
    : patientData;

  const primaryInsurance = patientData?.primaryInsurance?.data
    ? getFormattedObject(patientData?.primaryInsurance)
    : patientData?.primaryInsurance;
  const insuranceCompany = patientData?.insuranceCompany
    ? getFormattedObject(patientData?.insuranceCompany)
    : primaryInsurance?.company;

  return {
    id: patient?._id,
    gender: user?.gender,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phone: user?.phone,
    profession: user?.profession,
    birthdate: user?.birthdate,
    legalGuardians: [],
    patients: [
      {
        id: user?._id,
        careLevel: patient?.careLevel,
        familyDoctor: patient?.familyDoctor,
        insuranceNumber: primaryInsurance?.id || insuranceCompany?.id,
        nextAppointment: patient?.nextAppointment,
        image: user?.image,
        ward: patient?.isWard,
        status: patient?.status,
        insurance: {
          ...primaryInsurance,
          company: insuranceCompany,
        },
        notes: [],
        archived: false,
      },
    ],
    addresses: patientData?.addresses
      ? getAddresses(patientData?.addresses)
      : user?.addresses,
  };
};
