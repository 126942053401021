import React, { useRef } from 'react';
import { getConicGradient } from '../../utils/getConicGradient';

const DefaultImage = ({ className, value, dataTestId }) => {
  const style = useRef({
    background: value || getConicGradient(),
  });

  return (
    <div data-testid={dataTestId} className={className} style={style.current} />
  );
};

export default DefaultImage;
