import React from 'react';
import PropTypes from 'prop-types';
import {
  Eventcalendar as MobiEventCalendar,
  localeDe,
  localeEn,
} from '@mobiscroll/react';
import { noop } from 'lodash';
import { useTranslation } from '@dechea/orc.services.library';

import CalendarNavHeader from '../CalendarNavHeader/CalendarNavHeader';
import { DAY_VIEW, MONTH_VIEW, WEEK_VIEW } from '../../constants/views';
import EventLabel from '../EventLabel/EventLabel';
import ScheduleEvent from '../ScheduleEvent/ScheduleEvent';

import './eventCalendar.css';
import MoreEventsPopup from '../MoreEventsPopup/MoreEventsPopup';

const calHeight = '100vh';

const EventCalendar = ({
  events,
  setEvents,
  view,
  handleChangeCalendarView,
  browserLanguage,
  onSelectedDateChange,
  onEventClick,
  onEventCreated,
  onEventDeleted,
  onEventUpdated,
  mySelectedDate,
  activeEventId,
  setSelectedDate,
}) => {
  const { t } = useTranslation();
  const locale = browserLanguage === 'de' ? localeDe : localeEn;

  const eventCalendarProps = {
    theme: 'ios',
    themeVariant: 'light',
    data: events,
    height: calHeight,
    view: view?.calView,
    locale: locale,
    clickToCreate: 'double',
    dragToCreate: true,
    dragToMove: true,
    dragToResize: true,
    selectedDate: mySelectedDate,
    onSelectedDateChange: onSelectedDateChange,
    onEventClick: onEventClick,
    onEventCreated: onEventCreated,
    onEventDeleted: onEventDeleted,
    onEventUpdated: onEventUpdated,
    onCellDoubleClick: onEventCreated,
    timeFormat: 'HH:mm',
    moreEventsText: t('calendar.showMoreEvents', {
      eventCount: '{count}',
    }),
    renderEvent: (props) => <MoreEventsPopup {...props} />,
    renderLabel: (props) => (
      <EventLabel activeEventId={activeEventId} {...props} />
    ),
    renderHeader: (headerProps) => (
      <CalendarNavHeader
        view={view?.viewMode}
        setSelectedDate={setSelectedDate}
        mySelectedDate={mySelectedDate}
        onChangeView={handleChangeCalendarView}
        {...headerProps}
      />
    ),
    renderScheduleEvent: (props) => (
      <ScheduleEvent
        view={view?.viewMode}
        activeEventId={activeEventId}
        {...props}
      />
    ),
  };

  return (
    <div
      id="mobEventCalendar"
      data-testid="appointmentsContainer"
      className="md-switching-view-cont"
    >
      <MobiEventCalendar {...eventCalendarProps} />
    </div>
  );
};

EventCalendar.propTypes = {
  events: PropTypes.array.isRequired,
  setEvents: PropTypes.func.isRequired,
  view: PropTypes.oneOf([DAY_VIEW, WEEK_VIEW, MONTH_VIEW]),
  handleChangeCalendarView: PropTypes.func.isRequired,
  browserLanguage: PropTypes.string.isRequired,
  onSelectedDateChange: PropTypes.func.isRequired,
  onEventClick: PropTypes.func.isRequired,
  onEventCreated: PropTypes.func.isRequired,
  onEventDeleted: PropTypes.func.isRequired,
  onEventUpdated: PropTypes.func.isRequired,
  mySelectedDate: PropTypes.func.isRequired,
  activeEventId: PropTypes.number,
  setSelectedDate: PropTypes.func.isRequired,
};

EventCalendar.defaultProps = {
  events: [],
  setEvents: noop,
  view: MONTH_VIEW,
  handleChangeCalendarView: noop,
};

export default EventCalendar;
