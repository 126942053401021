const MuiFormLabel = {
  root: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    color: '#7694BC',
    '& .Mui-error': {
      color: '#f44336 !important',
    },
  },
  underline: {
    '&:hover:not($disabled):before': {
      borderBottom: '1px solid #E1E7F2',
    },
  },
};

export default MuiFormLabel;
