export const getEmployeeManipulatedData = (employeeData) => {
  if (!employeeData) {
    return null;
  }
  const employee = {
    employeeId: employeeData?.employee?.ref?.value?.id,
    firstName: employeeData?.user?.data?.firstName,
    lastName: employeeData?.user?.data?.lastName,
    birthdate: employeeData?.user?.data?.birthdate,
    image: employeeData?.user?.data?.image,
    id: employeeData?.user?.ref?.value?.id,
  };
  return employee;
};
