import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@dechea/orc.services.library';

import Popup from '../Mobiscroll/Popup/Popup';
import PatientEventOverview from '../PatientEventOverview/PatientEventOverview';
import useGAEventTracker from '../../hooks/useGAEventTracker';
import CalendarService from '../../api/calendar';
import { notificationTypes } from '../../constants/notification';

const notification = {
  type: notificationTypes.SUCCESS,
  open: true,
  message: '',
};

const EventOverview = ({
  isEventClicked,
  event,
  onClose,
  anchor,
  events,
  setEvents,
  displayNotification,
  setEventClicked,
  onClickEditEventButton,
}) => {
  const trackEvent = useGAEventTracker('CALENDAR');
  const { t } = useTranslation();

  const onClickDeleteEventButton = async (eId) => {
    let eventsCopy = [...events];
    try {
      await CalendarService.deleteEvent(eId);
      trackEvent('Appointment deleted');
      const eventIndex = events.findIndex((eventObj) => eventObj.id === eId);
      eventsCopy.splice(eventIndex, 1);

      notification.type = notificationTypes.SUCCESS;
      notification.message = t('calendar.notification.eventDeleted');
    } catch (error) {
      eventsCopy = [...events];

      notification.type = notificationTypes.FAILED;
      notification.message = t('calendar.notification.failedToDeleteEvent');
    } finally {
      setEvents(eventsCopy);
      setEventClicked(false);
      displayNotification(notification);
    }
  };

  return (
    <Popup
      isOpen={isEventClicked}
      onClose={onClose}
      anchor={anchor}
      width={360}
    >
      <PatientEventOverview
        event={event}
        onClickDeleteEventButton={onClickDeleteEventButton}
        onClickEditEventButton={onClickEditEventButton}
      />
    </Popup>
  );
};

EventOverview.propTypes = {
  isEventClicked: PropTypes.bool,
  event: PropTypes.object,
  onClose: PropTypes.func,
  anchor: PropTypes.element,
  events: PropTypes.array,
  setEvents: PropTypes.func,
  displayNotification: PropTypes.func,
  setEventClicked: PropTypes.func,
  onClickEditEventButton: PropTypes.func,
};

export default EventOverview;
