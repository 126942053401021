import combineReducers from './combinerReducers';
import notificationReducer from './reducers/notification';
import eventsReducer from './reducers/events';

// Need to add reducers here
export const reducer = {
  notificationReducer,
  eventsReducer,
};

// Reducers to be combiner here similar to redux
export default combineReducers(reducer);
