import { useReducer } from 'react';

export default function (reducer, initialState) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const customDispatch = (action) => {
    if (typeof action === 'function') {
      return action(customDispatch);
    } else {
      dispatch(action);
    }
  };

  return [state, customDispatch];
}
