const MuiInput = {
  root: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    color: '#7694BC',
  },
  underline: {
    '&:hover:not($disabled):before': {
      borderBottom: '1px solid #E1E7F2',
    },
  },
  input: {
    color: '#263857',
  },
};

export default MuiInput;
