import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import { noop } from 'lodash';
import { useTranslation } from '@dechea/orc.services.library';

import { useStyles } from './styles';
import Tab from '../../Mui/Tab/Tab';

const EventTabs = ({ activeTabIndex, handleChangeTabs }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Tabs
      data-testid="eventTabsContainer"
      TabIndicatorProps={{ className: styles.tabIndicator }}
      classes={{ root: styles.root }}
      value={activeTabIndex}
      onChange={handleChangeTabs}
    >
      <Tab
        label={t('calendar.event.patientEvent')}
        data-testid="patientEventTab"
      />
      <Tab
        label={t('calendar.event.customEvent')}
        data-testid="customEventTab"
        disabled
      />
    </Tabs>
  );
};

EventTabs.propTypes = {
  activeTabIndex: PropTypes.number.isRequired,
  handleChangeTabs: PropTypes.func.isRequired,
};

EventTabs.defaultProps = {
  handleChangeTabs: noop,
};

export default EventTabs;
