import colors from '../colors';

const MuiDialogContent = {
  dividers: {
    borderTop: `1px solid ${colors.lightSolitudeGray}`,
    borderBottom: `1px solid ${colors.lightSolitudeGray}`,
  },
};

export default MuiDialogContent;
