import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  scheduleEventWrapper: ({ bgColor }) => ({
    backgroundColor: bgColor,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    borderRadius: '0 4px 4px 0',
  }),
  scheduleEventSidebar: ({ sidebarBgColor }) => ({
    width: 2,
    backgroundColor: sidebarBgColor,
    height: '100%',
    flexShrink: 0,
  }),
  bodyContainer: {
    padding: '8px 12px 0',
    width: '100%',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
  },
  sepratorDot: ({ dotColor }) => ({
    width: 2,
    height: 2,
    margin: '0 4px',
    backgroundColor: dotColor,
  }),
  headingFont: ({ primaryColor }) => ({
    fontSize: 10,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '130%',
    letterSpacing: '0.03em',
    color: primaryColor,
  }),
  eventTypeContainer: {
    marginTop: 1,
  },
  eventTypesFont: ({ secondaryColor }) => ({
    fontSize: 12,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '130%',
    color: secondaryColor,
  }),
  ellipsisFont: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  time: {
    whiteSpace: 'nowrap',
  },
  newEventContainer: {
    background: theme.palette.shipCove,
    color: theme.palette.common.white,
    borderRadius: '0 4px 4px 0',
  },
  newEventBody: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
