const colors = {
  aliceBlue: '#EFF5FC',
  weldonBlue: '#7E98BA',
  marinerBlue: '#346BD1',
  cyanBlue: '#7694BC',
  waikawaGray: '#556E9A',
  paleNavyBlue: '#ADBDD8',
  rockBlue: '#91a1bf',
  catalinaBlue: '#263857',
  darkCatalinaBlue: '#1F3764',
  royalBlue: '#4A7CDA',
  royalBlueVariant: '#2F70E8',
  spindleGray: '#B6C0D1',
  solitudeGray: '#E8ECF2',
  lightSolitudeGray: '#E0E8F7',
  shipCoveGray: '#8494B1',
  midnightExpressBlue: '#0D1E3A',
  sapphireBlue: '#142A51',
  gulfBlue: '#314363',
  redOrange: '#f44336',
  amaranthRed: '#DC3545',
  whiteLilac: '#DEE5EE',
  hawkesBlue: '#D2DCE9',
  pantoneBlue: '#EBF0F6',
  cabaretRed: '#E04C66',
  lavender: '#E1E7F2',
  signalBlue: '#25406D',
  glaucous: '#6c86b5',
  radicalRed: '#FF1C5C',
  cardinalRed: '#C21044',
  carnationPink: '#FF98CC',
  paleVioletRed: '#DE5896',
  mayaBlue: '#68D6FF',
  bleuDeFrance: '#307CE7',
  aeroBlue: '#8eb2f4',
  bluetifulBlue: '#3a79ee',
  tumbleweedBrown: '#E1A68F',
  watusiOrange: '#F0C7B7',
  feldsparRed: '#CD8E74',
  black: '#000000',
  selagoBlue: '#F3F8FE',
  rhinoBlue: '#314363',
  portGoreBlue: '#3c4e6f',
  shipCoveBlue: '#8494b1',
  poloBlue: '#869DC5',
  zircon: '#FCFDFF',
  astronautBlue: '#254070',
  cornHarvest: '#926B08',
  earlyDawn: '#FFF8E6',
  lavenderBlush: '#FFEEF1',
  wedgewood: '#5278AA',
  periwinkleGray: '#C4D3E7',
  deepCove: '#051A3E',
  biscay: '#193566',
  linkWater: '#E0E8F7',
  stTropaz: '#2E5393',
  cornflowerBlue: '#6195ED',
  downriver: '#0E2A5A',
  blueZodiac: '#142B54',
  wildWatermelon: '#FF5573',
  cadillac: '#A45260',
  tamarind: '#321318',
  canCan: '#DEA1AA',
  frostedMint: '#E5FFF5',
  shamrock: '#2ED1A0',
  como: '#4B7869',
  bush: '#0E2820',
  vistaBlue: '#94D0BB',
  magnolia: '#F6F3FF',
  purpleHeart: '#673CE2',
  kimberly: '#73649D',
  violet: '#17093E',
  whitePointer: '#FCE8FF',
  amethyst: '#BC4ACE',
  trendyPink: '#8B5793',
  grape: '#411B47',
  lightWisteria: '#DAB0E0',
  earlyDawn2: '#FFF8E7',
  energyYellow: '#F9CE60',
  pesto: '#866F35',
  onion: '#382D10',
  sapling: '#DCCCA5',
  shipCove: '#6C86B5',
  white: '#ffffff',
  mandy: '#E04D67',
  bayOfMany: '#2A4981',
  whitePointer1: '#FEF8FF',
  steelBlue: '#5078BD',
  pigeonPost: '#A0B5D9',
};

export default colors;
