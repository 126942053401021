const MuiTextField = {
  root: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    background: 'transparent',
    '& .Mui-focused': {
      color: '#346BD1 !important',
    },
    '& .MuiFormLabel-root': {
      color: '#7694BC',
    },
    '& .Mui-error': {
      color: '#DC3545',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #E1E7F2',
      '&:hover': {
        borderBottom: '1px solid #E1E7F2',
      },
    },
    '& ::after': {
      borderBottom: '1px solid #E1E7F2',
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: '#DC3545',
    },
    '& .MuiInput-underline.Mui-focused:after': {
      borderBottomColor: '#7694BC !important',
    },
  },
};

export default MuiTextField;
