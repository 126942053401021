import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import React from 'react';
import {
  ThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';
import { i18n, useTranslation } from '@dechea/orc.services.library';
import moment from 'moment';

import Calendar from './Calendar/Calendar';
import { ContextProvider } from '../store/provider';
import { muiTheme } from '../theme/index';
import Snackbar from '../components/Mui/Snackbar';

import 'moment/locale/de';
import 'moment/locale/en-au';

const generateClassName = createGenerateClassName({
  productionPrefix: 'dechea-calendar',
  disableGlobal: true,
});

const FeCalendar = () => {
  useTranslation();

  const browserLanguage = i18n?.language;

  browserLanguage === 'de' ? moment.locale('de') : moment.locale('en');

  return (
    <div className="rootWrapper">
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={muiTheme}>
          <ContextProvider>
            <Calendar browserLanguage={browserLanguage} />
            <Snackbar />
          </ContextProvider>
        </ThemeProvider>
      </StylesProvider>
    </div>
  );
};

export default FeCalendar;
