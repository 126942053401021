export const plusIconWithBlueBG =
  'https://storage.googleapis.com/dechea-microservices-files/patient-management/plusIconWithBlueBG.svg';
export const personIcon =
  'https://storage.googleapis.com/dechea-microservices-files/patient-management/personIcon.svg';
export const serachIconGrey =
  'https://storage.googleapis.com/dechea-microservices-files/patient-management/searchIconGrey.svg';

export const moreDots =
  'https://storage.googleapis.com/dechea-microservices-files/calendar/moreDots.svg';
export const whiteDownArrow =
  'https://storage.googleapis.com/dechea-microservices-files/calendar/whiteDownArrow.svg';
export const eventClock =
  'https://storage.googleapis.com/dechea-microservices-files/calendar/eventClock.svg';
export const darkUpArrow =
  'https://storage.googleapis.com/dechea-microservices-files/calendar/darkUpArrow.svg';
export const darkDownArrow =
  'https://storage.googleapis.com/dechea-microservices-files/calendar/darkDownArrow.svg';

export const successIcon =
  'https://storage.googleapis.com/dechea-microservices-files/patient-management/successIcon.svg';
export const failureIcon =
  'https://storage.googleapis.com/dechea-microservices-files/patient-management/failureIcon.svg';
