import React from 'react';
import ReactSelect from 'react-select';
import { useTranslation } from '@dechea/orc.services.library';

import CrossIcon from '../../../../assets/icons/CrossIcon';
import SearchIcon from '../../../../assets/icons/SearchIcon';
import styles from '../../patientDropdown.module.scss';
import PatientList from '../PatientList/PatientList';

const DropdownBody = ({
  patientList,
  style,
  handleChange,
  setSelectedPatient,
  setIsMenuOpen,
  value,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.selectDropdown} data-testid="selectDropdown">
      <ReactSelect
        menuIsOpen
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        options={patientList}
        styles={style}
        onChange={(patient) => {
          handleChange(patient);
          setSelectedPatient(patient);
          setIsMenuOpen(false);
        }}
        components={{
          Option: PatientList,
          IndicatorSeparator: () => null,
          DropdownIndicator: () => (
            <>
              <div className={styles.searchIcon}>
                <SearchIcon />
              </div>
              <div className={styles.crossIcon}>
                <CrossIcon />
              </div>
            </>
          ),
        }}
        value={value}
        isSearchable={true}
        placeholder={`${t('calendar.event.findPatient')}...`}
        noOptionsMessage={() => (
          <div className={styles.noOptionMessage} data-testid="noOptionMessage">
            {t('calendar.event.noPatients')}
          </div>
        )}
      />
    </div>
  );
};

export default DropdownBody;
