import moment from 'moment';
import request from '../request';

function getEventTypes(page, pageSize) {
  return request({
    url: `/eventtypes?page=${page}&pageSize=${pageSize}`,
    method: 'GET',
  });
}

function createEvent(eventDetail) {
  return request({
    url: '/events',
    method: 'POST',
    data: eventDetail,
  });
}

function getEvents(page, pageSize) {
  return request({
    url: `/events?page=${page}&pageSize=${pageSize}`,
    method: 'GET',
  });
}

function deleteEvent(id) {
  return request({
    url: `/events/${id}/archive`,
    method: 'PUT',
  });
}

function getEventsByView(view, date, page, pageSize, archived = false) {
  const format = 'YYYY-MM-DDTHH:mm:ssZ';
  const startDate = moment.utc(moment(date).startOf(view)).format(format);
  const endDate = moment.utc(moment(date).endOf(view)).format(format);

  const encodedStartDate = encodeURIComponent(startDate);
  const encodedEndDate = encodeURIComponent(endDate);

  return request({
    url: `/events/dates/all?archived=${archived}&page=${page}&pageSize=${pageSize}&startDate=${encodedStartDate}&endDate=${encodedEndDate}`,
    method: 'GET',
  });
}

const CalendarService = {
  getEventTypes,
  createEvent,
  getEvents,
  deleteEvent,
  getEventsByView,
};

export default CalendarService;
