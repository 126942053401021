import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { ApolloProvider } from '@apollo/client';

import FeCalendar from './pages/index';
import { FaunaDbService } from './services/faunadb/faunadb';
import './index.css';

export let accessToken = '';
export let history = null;

window.renderAppointments = (
  containerId,
  skeletonHistory,
  isAuthenticated,
  token
) => {
  accessToken = token?.accessToken;
  history = skeletonHistory;

  if (history && !isAuthenticated) {
    history.push('/login');
    return;
  }
  if (!history) {
    window.location.href = process.env.REACT_APP_SKELETON_URL
      ? `${process.env.REACT_APP_SKELETON_URL}appointments`
      : 'http://localhost:8080/appointments';
    return;
  }

  ReactGA.initialize('UA-177361705-1', {
    testMode: process.env.NODE_ENV === 'test',
  });

  console.log('Calendar renderAppointments from microservice 1');

  getFaunaClient(accessToken)
    .then((faunaInstance) => {
      if (faunaInstance?.client) {
        ReactDOM.render(
          <ApolloProvider client={faunaInstance?.apolloClient}>
            <FeCalendar history={history} />,
          </ApolloProvider>,
          document.getElementById(containerId)
        );
      }
    })
    .catch((err) => {});
};

const getFaunaClient = (accessToken) => {
  return new Promise((resolve) => {
    const faunaInstance = FaunaDbService.initFaunaDb(accessToken);
    resolve(faunaInstance);
  });
};

window.unmountAppointments = (containerId) => {
  console.log('Calendar unmountAppointments from microservice');
  const node = document.getElementById(containerId);
  ReactDOM.unmountComponentAtNode(node);
};
