import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 24,
  },
  root: {
    background: theme.palette.biscay,
    borderRadius: 8,
    margin: '4px 4px 4px 0',
    '&:hover': {
      background: theme.palette.stTropaz,
    },
    '&:focus': {
      background: theme.palette.marinerBlue,
    },
  },
  label: {
    padding: '6px 12px',
    color: theme.palette.selagoBlue,
    fontSize: 12,
    lineHeight: '130%',
    fontWeight: 500,
    fontFamily: 'Montserrat',
  },
  deleteIcon: {
    border: `1px solid transparent`,
    paddingRight: 6,
    '&:hover': {
      background: theme.palette.astronautBlue,
      border: `1px solid ${theme.palette.stTropaz}`,
    },
    '&:focus': {
      background: theme.palette.astronautBlue,
      border: `1px solid ${theme.palette.marinerBlue}`,
    },
  },
  labelPaddingLeft: {
    paddingRight: 6,
  },
  title: {
    fontSize: 12,
    color: theme.palette.glaucous,
    fontWeight: 500,
    lineHeight: '140%',
    marginBottom: 4,
  },
  activeChip: {
    background: theme.palette.marinerBlue,
  },
  validationError: {
    color: theme.palette.wildWatermelon,
  },
  typeContainer: {
    minHeight: 120,
  },
  loader: {
    marginRight: 5,
  },
}));
