import React, { forwardRef } from 'react';

import PractitionerList from '../PractitionerList/PractitionerList';
import Search from '../Search/Search';
import SearchList from '../SearchList/SearchList';

const DropdownBody = forwardRef(
  (
    {
      onSearch,
      searchTerm,
      onClearInputText,
      searchOptions,
      selectedOptions,
      onSelectPractitioner,
      options,
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className="dropdown_select_container"
        data-testid="dropdownSelectContainer"
      >
        <div className="searchWrapper">
          <Search
            onSearch={onSearch}
            searchTerm={searchTerm}
            onClearInputText={onClearInputText}
          />
        </div>
        <div className="option_container" data-testid="optionContainer">
          {searchTerm ? (
            <SearchList
              searchOptions={searchOptions}
              selectedOptions={selectedOptions}
              onSelectSearchMember={onSelectPractitioner}
            />
          ) : (
            <PractitionerList
              options={options}
              selectedOptions={selectedOptions}
              onSelectPractitioner={onSelectPractitioner}
            />
          )}
        </div>
      </div>
    );
  }
);

export default DropdownBody;
