import colors from '../colors';

const MuiIconButton = {
  root: {
    '&:hover': {
      backgroundColor: colors.selagoBlue,
    },
  },
};

export default MuiIconButton;
