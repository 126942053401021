import { useEffect } from 'react';

const useBlurHandler = (ref, onClickOutside = () => {}) => {
  useEffect(() => {
    const handleMouseDown = (event) => {
      if (ref.current && !ref.current?.contains?.(event.target)) {
        setTimeout(() => {
          // ensure other callbacks are fired
          onClickOutside?.();
        }, 10);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [ref, onClickOutside]);
};

export default useBlurHandler;
