import React from 'react';
import PropTypes from 'prop-types';
import MuiTab from '@material-ui/core/Tab';

import { useStyles } from './styles';

const Tab = ({ label, dataTestId, ...props }) => {
  const styles = useStyles();

  return (
    <MuiTab
      classes={{
        root: styles.tabRoot,
        selected: styles.selectedTab,
        wrapper: styles.tabWrapper,
      }}
      label={label}
      data-testid={dataTestId}
      {...props}
    />
  );
};

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
};

export default Tab;
