import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './patientDropdown.module.scss';
import OutsideAlerter from '../OutsideClickHandler';
import DropdownHeader from './components/DropdownHeader/DropdownHeader';
import DropdownBody from './components/DropdownBody/DropdownBody';

export const preparePatientOption = (patient) => ({
  label: `${patient.firstName} ${patient.lastName}`,
  value: patient,
});

const PatientDropdown = ({ value, options, handleChange, style, isError }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(value || null);
  const [patientList, setPatientList] = useState([]);

  // TO FORMAT PATIENT DATA AS REACT-SELECT OPTIONS
  useEffect(() => {
    if (options && options.length) {
      const formatedOptions = options?.map((patient) =>
        preparePatientOption(patient)
      );
      setPatientList(formatedOptions);
    }
  }, [options]);

  return (
    <OutsideAlerter actionHandler={() => setIsMenuOpen(false)}>
      <div className={styles.scrollbar}>
        <DropdownHeader
          isError={isError}
          isMenuOpen={isMenuOpen}
          selectedPatient={selectedPatient}
          setIsMenuOpen={setIsMenuOpen}
        />
        {isMenuOpen && (
          <DropdownBody
            handleChange={handleChange}
            patientList={patientList}
            setIsMenuOpen={setIsMenuOpen}
            setSelectedPatient={setSelectedPatient}
            style={style}
            value={value}
          />
        )}
      </div>
    </OutsideAlerter>
  );
};

PatientDropdown.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  handleChange: PropTypes.func,
  style: PropTypes.object,
  isError: PropTypes.bool,
};

export default PatientDropdown;
