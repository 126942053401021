import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  tabRoot: {
    padding: '8.5px 16px',
    textTransform: 'capitalize',
    minWidth: '50%',
    minHeight: 'unset',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '140% !important',
    fontFamily: 'Montserrat !important',
    color: theme.palette.paleNavyBlue,
    '&:hover': {
      color: `${theme.palette.selagoBlue} !important`,
    },
  },
  selectedTab: {
    background: theme.palette.stTropaz,
    borderRadius: 8,
    color: `${theme.palette.common.white} !important`,
  },
  tabWrapper: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '140% !important',
    fontFamily: 'Montserrat !important',
  },
}));
