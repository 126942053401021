import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

export const breakPointsValues = {
  xs: 360,
  sm: 599,
  md: 1199,
  lg: 1600,
  xl: 1919,
};

export const breakPoints = createBreakpoints({
  values: breakPointsValues,
});

export default breakPoints;
