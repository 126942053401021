import React, { forwardRef } from 'react';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import { useStyles } from './styles';
import { whiteDownArrow } from '../../../constants/icons';

const Time = forwardRef(({ date, onClick, dataTestId }, ref) => {
  const styles = useStyles();

  return (
    <div
      data-testid={dataTestId}
      ref={ref}
      className={cx(
        styles.flexContainer,
        styles.pointerContainer,
        styles.hoveredDateAndTime
      )}
      onClick={onClick}
    >
      <Typography className={styles.dateAndTimeFont} data-testid="timeLabel">
        {moment(date).format('HH:mm')}
      </Typography>
      <img src={whiteDownArrow} alt="arrow" />
    </div>
  );
});

export default Time;
