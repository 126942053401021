/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@dechea/orc.services.library';

import Popup from '../Mobiscroll/Popup/Popup';
import EventTabs from '../EventComponents/EventTabs/EventTabs';
import MuiTabPanel from '../Mui/MuiTabPanel/MuiTabPanel';
import PatientEvent from '../PatientEvent/PatientEvent';
import CustomEvent from '../CustomEvent/CustomEvent';
import { notificationTypes } from '../../constants/notification';
import CalendarService from '../../api/calendar';
import useGAEventTracker from '../../hooks/useGAEventTracker';
import { eventColors } from '../../constants/eventLabelColors';
import { getEventTitle } from '../../utils/events';

const notification = {
  type: notificationTypes.SUCCESS,
  open: true,
  message: '',
};

const Event = ({
  isEdit,
  anchor,
  isOpen,
  onClose,
  tempEvent,
  events,
  setEvents,
  displayNotification,
  setSelectedDate,
  setEventPopupOpen,
  view,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const trackEvent = useGAEventTracker('CALENDAR');
  const { t } = useTranslation();

  const handleChangeTabs = (e, tabValue) => {
    setActiveTabIndex(tabValue);
  };

  const savePatientEvent = async (event) => {
    if (isEdit) {
      // update the event in the list
      const index = events.findIndex((x) => x.id === tempEvent.id);
      const newEventList = [...events];

      newEventList.splice(index, 1, event);
      setEvents(newEventList);
    } else {
      // add the new event to the list

      try {
        const eventRes = await CalendarService.createEvent(event);
        const formatedEvent = {
          ...eventRes,
          title: getEventTitle(eventRes),
          start: new Date(eventRes.startDate),
          end: new Date(eventRes.endDate),
          color: eventColors[eventRes?.eventTypes?.[0]?.titleCode],
        };
        trackEvent('Appointment created', event?.title);
        setEvents([...events, formatedEvent]);
        notification.type = notificationTypes.SUCCESS;
        notification.message = t('calendar.notification.eventCreate');
      } catch (error) {
        notification.type = notificationTypes.FAILED;
        notification.message = t('calendar.notification.failedToCreateEvent');
      }
    }

    setSelectedDate(event?.startDate);
    setEventPopupOpen(false);

    displayNotification(notification);
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      display="bottom"
      fullScreen={true}
      contentPadding={false}
      anchor={anchor}
      width={360}
    >
      <EventTabs
        activeTabIndex={activeTabIndex}
        handleChangeTabs={handleChangeTabs}
      />
      <MuiTabPanel index={activeTabIndex} value={0}>
        <PatientEvent
          isEdit={isEdit}
          isOpen={isOpen}
          saveEvent={savePatientEvent}
          tempEvent={tempEvent}
          onClickDeleteEventButton={() => null}
        />
      </MuiTabPanel>
      <MuiTabPanel index={activeTabIndex} value={1}>
        <CustomEvent />
      </MuiTabPanel>
    </Popup>
  );
};

Event.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  anchor: PropTypes.any,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tempEvent: PropTypes.object,
  events: PropTypes.array.isRequired,
  setEvents: PropTypes.func.isRequired,
  displayNotification: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  setEventPopupOpen: PropTypes.func.isRequired,
};

export default Event;
