import colors from '../colors';

const MuiTab = {
  root: {
    padding: '8px 16px 12px 16px',
  },
  selected: {},
  textColorInherit: {
    color: colors.poloBlue,
    '&$selected': {
      color: colors.marinerBlue,
    },
  },
  wrapper: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.4,
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
};

export default MuiTab;
