import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  practitionerLabel: {
    fontSize: 12,
    lineHeight: '145%',
    fontWeight: 500,
    color: theme.palette.poloBlue,
  },
}));
