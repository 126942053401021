import React from 'react';

const CrossIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.306 4.69436C12.3795 1.76855 7.62031 1.76855 4.69388 4.69436C1.76871 7.62018 1.76871 12.3806 4.69388 15.3064C6.1571 16.769 8.07865 17.5 10.0002 17.5C11.9218 17.5 13.8427 16.769 15.306 15.3064C18.2318 12.3806 18.2318 7.62018 15.306 4.69436ZM13.0949 12.2112C13.3393 12.4557 13.3393 12.8509 13.0949 13.0953C12.9729 13.2173 12.8129 13.2786 12.6528 13.2786C12.4927 13.2786 12.3326 13.2173 12.2107 13.0953L10.0002 10.8842L7.79038 13.0947C7.66783 13.2166 7.50773 13.2779 7.34828 13.2779C7.18821 13.2779 7.02811 13.2166 6.90617 13.0947C6.66169 12.8502 6.66169 12.4544 6.90617 12.2105L9.11601 10.0001L6.90556 7.78963C6.66107 7.54514 6.66107 7.14933 6.90556 6.90546C7.14943 6.66097 7.54525 6.66097 7.78973 6.90546L10.0002 9.1159L12.2106 6.90546C12.4551 6.66097 12.8503 6.66097 13.0948 6.90546C13.3393 7.14933 13.3393 7.54514 13.0948 7.78963L10.8844 10.0001L13.0949 12.2112Z"
        fill="#6C86B5"
      />
    </svg>
  );
};

export default CrossIcon;
