import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import cx from 'classnames';
import moment from 'moment';

import { eventClock } from '../../../../constants/icons';
import GridContainer from '../../../Mui/GridContainer/GridContainer';
import { useStyles } from './styles';

const EventDateAndTime = ({ event }) => {
  const styles = useStyles();

  const isAllDay = event?.allDay;

  const getDate = (date) => moment(date).format('MMM DD, YYYY');
  const getTime = (date) => moment(date).format('HH:mm');

  return (
    <GridContainer>
      <GridContainer
        alignItems="center"
        className={styles.root}
        data-testid="dataAndTimeOverview"
      >
        <img className={styles.clock} src={eventClock} alt="clock" />
        <Typography
          className={styles.dateAndTime}
          data-testid="startDateOverviewContainer"
        >
          {getDate(event?.start)}
        </Typography>

        {isAllDay ? (
          <>
            <Typography className={cx(styles.dateAndTime, styles.dash)}>
              –
            </Typography>
            <Typography
              className={styles.dateAndTime}
              data-testid="endDateOverviewContainer"
            >
              {getDate(event?.end)}
            </Typography>
          </>
        ) : (
          <>
            <div className={styles.divider} />
            <Typography
              className={styles.dateAndTime}
              data-testid="startTimeOverview"
            >
              {getTime(event?.start)}
            </Typography>
            <Typography className={cx(styles.dateAndTime, styles.dash)}>
              –
            </Typography>
            <Typography
              className={styles.dateAndTime}
              data-testid="endTimeOverview"
            >
              {getTime(event?.end)}
            </Typography>
          </>
        )}
      </GridContainer>
    </GridContainer>
  );
};

EventDateAndTime.propTypes = {
  event: PropTypes.object,
};

export default EventDateAndTime;
