import colors from '../colors';

const MuiButton = {
  root: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    padding: '12px 24px',
    borderRadius: 50,
  },
  label: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.4,
    textTransform: 'none',
  },
  containedPrimary: {
    backgroundColor: colors.marinerBlue,
    '&:hover': {
      backgroundColor: colors.royalBlueVariant,
    },
    '&:focus': {
      outline: 'none',
      border: 'none',
    },
    '& $label': {
      color: '#FFFFFF',
    },
  },
  outlined: {
    padding: '11px 23px',
    border: `1px solid ${colors.lightSolitudeGray}`,
    backgroundColor: 'transparent',
    '& $label': {
      color: colors.rhinoBlue,
    },
    '&:hover': {
      borderColor: colors.glaucous,
      backgroundColor: 'transparent',
    },
  },
  iconSizeMedium: {
    '& > *:first-child': {
      fontSize: 'unset',
    },
  },
  startIcon: {
    marginRight: 12,
    marginLeft: 'unset',
  },
};

export default MuiButton;
