/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Dropdown from './components/Dropdown/Dropdown';
import { callLambdaFunction } from '../../utils/faunadb/callLambdaFunction/callLambdaFunction';
import { USR_GETEMPLOYEES } from '../../constants/faunadb/functions';
import { EMPLOYEE } from '../../constants/faunadb/collections';
import { getEmployeeManipulatedData } from '../../utils/manipulatePatientData/getManipulatedEmployeeData';

const DEFAULT_PAGE_SIZE = 15;

const EmployeeDropdown = ({ selectedOptions, setSelected, isError }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [matchedOptions, setMatchedOptions] = useState([]);

  const [practitioners, setPractitioners] = useState([]);
  const [options, setOptions] = useState([]);
  const [employeeAfterCursor, setEmployeeAfterCursor] = useState(null);

  const [isAllOptionsSelected, setIsAllOptionsSelected] = useState(false);
  const [selectedOptionsList, setSelectedOptionsList] = useState([]);
  const [hasMoreEmployees, setHasMoreEmployees] = useState(true);
  const [employeePage, setEmployeePage] = useState(1);

  const manipulateEmployee = (faunadbEmployee) => {
    return faunadbEmployee?.map((employee) =>
      getEmployeeManipulatedData(employee)
    );
  };

  const getEmployeeList = async () => {
    try {
      if (hasMoreEmployees) {
        const gqlEmployees = await callLambdaFunction(
          USR_GETEMPLOYEES,
          EMPLOYEE,
          DEFAULT_PAGE_SIZE,
          employeeAfterCursor
        );
        const EmployeesData = manipulateEmployee(gqlEmployees?.employee);
        setPractitioners([...practitioners, ...EmployeesData]);

        if (gqlEmployees?.after[0]?.value.id) {
          setHasMoreEmployees(true);
          setEmployeePage(employeePage + 1);
          setEmployeeAfterCursor(gqlEmployees?.after[0]?.value.id);
        } else {
          setHasMoreEmployees(false);
        }
      }
    } catch (error) {
      setHasMoreEmployees(false);
    }
  };

  useEffect(() => {
    getEmployeeList();
  }, []);

  useEffect(() => {
    if (employeePage !== 1) {
      getEmployeeList();
    }
  }, [employeeAfterCursor]);

  // TO REFACTOR PRACTITIONER DATA
  useEffect(() => {
    if (practitioners.length) {
      const formattedEmployees = [];

      practitioners.forEach((option) => {
        option['displayName'] = `${option.firstName} ${option.lastName}`;
        let avatarUrl;
        if (option.image) {
          avatarUrl = window.atob(option.image);
        }
        if (avatarUrl && avatarUrl.includes('data'))
          option['avatar'] = avatarUrl;
        else option['avatar'] = null;

        formattedEmployees.push(option);
      });

      setOptions(formattedEmployees);
    }
  }, [practitioners]);

  // TO SET SELECTED PRACTITIONER DETAIL
  useEffect(() => {
    if (selectedOptions?.length === options?.length) {
      setIsAllOptionsSelected(true);
    } else {
      setIsAllOptionsSelected(false);
    }
    const optionsCheck = options.filter((option) =>
      selectedOptions.includes(option.id)
    );
    setSelectedOptionsList(optionsCheck);
  }, [selectedOptions]);

  // for select and un-select practitioner
  const onSelectPractitioner = async (id) => {
    const selectedIndex = selectedOptions?.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedOptions, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedOptions?.slice(1));
    } else if (selectedIndex === selectedOptions?.length - 1) {
      newSelected = newSelected.concat(selectedOptions?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedOptions?.slice(0, selectedIndex),
        selectedOptions?.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // call on search member
  const onSearch = async (e) => {
    setSearchTerm(e?.target?.value);

    const result = options.filter((option) =>
      option.displayName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setMatchedOptions(result);
  };

  // clear search input data.
  const onClearInputText = () => {
    setSearchTerm('');
    setMatchedOptions([]);
  };

  return (
    <Dropdown
      options={options}
      isError={isError}
      selectedOptions={selectedOptions}
      onSelectPractitioner={onSelectPractitioner}
      onSearch={onSearch}
      onClearInputText={onClearInputText}
      searchTerm={searchTerm}
      searchOptions={matchedOptions}
      isAllOptionsSelected={isAllOptionsSelected}
      selectedOptionsList={selectedOptionsList}
    />
  );
};

EmployeeDropdown.propTypes = {
  selectedOptions: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default EmployeeDropdown;
