import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  eventBody: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  patientAndEmployeeWrapper: {
    padding: '0 12px',
  },
  patientDropdownWrapper: {
    marginTop: 20,
    marginBottom: 12,
  },
  divider: {
    borderTop: `1px solid ${theme.palette.blueZodiac}`,
    margin: '0 12px',
  },
  employeeDropdownWrapper: {
    margin: '12px auto',
  },
}));
