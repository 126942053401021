import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { Datepicker } from '@mobiscroll/react';
import PropTypes from 'prop-types';

import { useStyles } from './styles';
import ArrowIcon from './ArrowIcon';

const NavCalendar = ({ date, setSelectedDate }) => {
  const [isShowDateCalendar, setShowDateCalendar] = useState(false);
  const [dateRef, setDateRef] = useState(null);
  const styles = useStyles();

  const toggleShowDateCalendar = () => {
    setShowDateCalendar(!isShowDateCalendar);
  };
  const onChangeDate = (args) => {
    setSelectedDate(args.value);
  };

  return (
    <>
      <div
        data-testid="navCalendar"
        ref={setDateRef}
        onClick={toggleShowDateCalendar}
        className={styles.flexContainer}
      >
        <Typography
          data-testid="mothYearLabel"
          className={styles.monthYearLabel}
        >
          <span>{moment(date).format('MMMM, ')}</span>
          <span>{moment(date).format('YYYY')}</span>
        </Typography>
        <div className={styles.arrowContainer}>
          <ArrowIcon isShowDateCalendar={isShowDateCalendar} />
        </div>
      </div>

      <Datepicker
        controls={['calendar']}
        display="anchored"
        anchor={dateRef}
        touchUi={false}
        isOpen={isShowDateCalendar}
        onClose={toggleShowDateCalendar}
        showInput={false}
        value={date}
        onChange={onChangeDate}
      />
    </>
  );
};

NavCalendar.propTypes = {
  date: PropTypes.object,
  setSelectedDate: PropTypes.func,
};

export default NavCalendar;
