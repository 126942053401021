import colors from '../colors';

const MuiInputLabel = {
  root: {
    '&$focused': {
      color: colors.marinerBlue,
    },
  },
  focused: {},
};

export default MuiInputLabel;
