import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  advanceSettingCollapse: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 8,
    padding: '4px 24px',
    marginBottom: 24,
    width: 'fit-content',
    color: theme.palette.poloBlue,
    '&:hover': {
      color: theme.palette.selagoBlue,
    },
  },
  advanceSetting: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '130%',
    color: 'inherit',
    paddingRight: 4,
  },
  advanceSettingCollapseIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  lessOptionsIcon: {
    transform: 'rotate(180deg)',
    marginTop: 3,
  },
  allDayWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 18,
    padding: '0 24px',
  },
  allDayContainer: {
    padding: 0,
    margin: 0,
    color: theme.palette.poloBlue,
    '&:hover': {
      color: theme.palette.selagoBlue,
    },
  },
  allDayLabel: {
    color: 'inherit',
    fontSize: 14,
    lineHeight: '140%',
    fontWeight: 500,
  },
  allDayIcon: {
    boxSizing: 'border-box',
    borderRadius: 4,
    width: 16,
    height: 16,
    border: `1px solid ${theme.palette.poloBlue}`,
    backgroundColor: theme.palette.deepCove,
    'input:hover ~ &': {
      backgroundColor: theme.palette.deepCove,
      border: `1px solid ${theme.palette.selagoBlue}`,
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.marinerBlue,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 16,
    backgroundPosition: 'center',
    backgroundImage:
      'url(https://storage.googleapis.com/dechea-microservices-files/calendar/checkTick.svg)',
    backgroundRepeat: 'no-repeat',
  },
  checkbox: {
    paddingLeft: 1.5,
    paddingTop: 0,
    paddingBottom: 0,
  },
  selectedTimeFont: {
    color: theme.palette.waikawaGray,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '140%',
  },
  advanceSettingContainer: {
    marginTop: 10,
  },
  divider: {
    borderTop: `1px solid ${theme.palette.blueZodiac}`,
    margin: '0 12px',
  },
  alertWrapper: {
    margin: '12px 0',
  },
  repeatWrapper: {
    marginTop: 12,
    marginBottom: -6,
  },
  dropdownWrapper: {
    padding: '0 12px',
  },
}));
