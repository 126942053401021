import { getConcatedEventTypes } from './getFormattedEventTypes';
import { getPatientName } from './getPatientName';

export const getEventDateWithTime = (date, time) => {
  const formattedDate = new Date(date);
  const hours = time?.getHours();
  const minutes = time?.getMinutes();

  formattedDate?.setHours(hours);
  formattedDate?.setMinutes(minutes);

  return formattedDate;
};

export const getFormattedStartAndEndDate = (
  eventDate,
  eventTime,
  isAllDayEvent
) => {
  let startDate = null;
  let endDate = null;

  if (isAllDayEvent) {
    startDate = eventDate?.[0];
    endDate = eventDate?.[1];
  } else {
    startDate = getEventDateWithTime(eventDate?.[0], eventTime?.[0]);
    endDate = getEventDateWithTime(eventDate?.[1], eventTime?.[1]);
  }

  return { startDate, endDate };
};

export const getEventTitle = (event) =>
  `${getPatientName(event?.patientEntity)}, ${getConcatedEventTypes(
    event?.eventTypes
  )}`;
