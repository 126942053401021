import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    minWidth: 180,
    justifyContent: 'flex-end',
    '&:hover': {
      '& :first-child': {
        '& :first-child': {
          color: theme.palette.wedgewood,
        },
        '& :last-child': {
          color: theme.palette.lightSolitudeGray,
        },
      },
    },
  },
  pointerContainer: {
    cursor: 'pointer',
  },
  monthYearLabel: {
    fontSize: 16,
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    '& :first-child': {
      color: theme.palette.rhinoBlue,
    },
    '& :last-child': {
      color: theme.palette.pigeonPost,
    },
  },
  arrowContainer: {
    marginRight: 16,
    marginLeft: 8,
  },
}));
