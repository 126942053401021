import React, { forwardRef } from 'react';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import { useStyles } from './styles';
import { whiteDownArrow } from '../../../constants/icons';

const Date = forwardRef(({ onClick, date }, ref) => {
  const styles = useStyles();

  return (
    <div
      data-testid="dateContainer"
      ref={ref}
      onClick={onClick}
      className={cx(styles.hoveredDateAndTime, styles.flexContainer)}
    >
      <Typography className={styles.dateAndTimeFont} data-testid="dateLabel">
        {moment(date).format('MMM D, YYYY')}
      </Typography>
      <img src={whiteDownArrow} alt="arrow" />
    </div>
  );
});

export default Date;
