import React from 'react';
import PropTypes from 'prop-types';

import EventTypes from '../EventComponents/EventTypes/EventTypes';
import EventTime from '../EventComponents/EventTime/EventTime';
import Footer from '../EventComponents/Footer/Footer';
import { useStyles } from './styles';
import EmployeeDropdown from '../EmployeeDropdown/EmployeeDropdown';
import PatientDropdown from '../PatientDropdown/PatientDropdown';
import { SELECT_PATIENT_STYLES } from '../../constants/styles';

const PatientEventForm = ({
  isEdit,
  isAllDayEvent,
  toggleAllDayEvent,
  selectedEmployee,
  setSelectedEmployee,
  eventTypes,
  patientList,
  selectedPatient,
  setSelectedPatient,
  onSelectEventType,
  selectedEventTypes,
  onClickCreateEventButton,
  onChangeEventTime,
  eventTime,
  eventDate,
  onChangeEventDate,
  errors,
  onClickDeleteEventButton,
  eventId,
}) => {
  const styles = useStyles();

  return (
    <>
      <div data-testid="eventFormContainer">
        <div className={styles.patientAndEmployeeWrapper}>
          <div className={styles.patientDropdownWrapper}>
            <PatientDropdown
              style={SELECT_PATIENT_STYLES}
              options={patientList}
              value={selectedPatient}
              handleChange={setSelectedPatient}
              isError={errors?.patient}
            />
          </div>
          <div className={styles.divider} />
          <div className={styles.employeeDropdownWrapper}>
            <EmployeeDropdown
              selectedOptions={selectedEmployee}
              setSelected={setSelectedEmployee}
              isError={errors?.practitioner}
            />
          </div>
          <div className={styles.divider} />
        </div>
        <div className={styles.eventBody}>
          <EventTypes
            eventTypes={eventTypes}
            onSelectEventType={onSelectEventType}
            selectedEventTypes={selectedEventTypes}
            isError={errors?.eventType}
          />
        </div>
        <EventTime
          isAllDayEvent={isAllDayEvent}
          toggleAllDayEvent={toggleAllDayEvent}
          onChangeEventTime={onChangeEventTime}
          eventTime={eventTime}
          eventDate={eventDate}
          onChangeEventDate={onChangeEventDate}
        />
      </div>

      <Footer
        eventId={eventId}
        onClickCreateEventButton={onClickCreateEventButton}
        onClickDeleteEventButton={onClickDeleteEventButton}
      />
    </>
  );
};

PatientEventForm.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  isAllDayEvent: PropTypes.bool.isRequired,
  toggleAllDayEvent: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.array.isRequired,
  setSelectedEmployee: PropTypes.func.isRequired,
  eventTypes: PropTypes.array.isRequired,
  patientList: PropTypes.array.isRequired,
  selectedPatient: PropTypes.object.isRequired,
  setSelectedPatient: PropTypes.func.isRequired,
  onSelectEventType: PropTypes.func.isRequired,
  selectedEventTypes: PropTypes.array.isRequired,
  onClickCreateEventButton: PropTypes.func.isRequired,
  onChangeEventTime: PropTypes.func.isRequired,
  eventTime: PropTypes.array.isRequired,
  eventDate: PropTypes.array.isRequired,
  onChangeEventDate: PropTypes.func.isRequired,
  errors: PropTypes.object,
  onClickDeleteEventButton: PropTypes.func,
  eventId: PropTypes.number,
};

export default PatientEventForm;
