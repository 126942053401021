import { SET_EVENTS } from '../actions/events';

export const eventInitialState = {
  events: [],
};

const eventsReducer = (state = eventInitialState, action) => {
  const type = action?.type;
  const payload = action?.payload;

  switch (type) {
    case SET_EVENTS:
      return {
        ...state,
        events: payload,
      };

    default:
      return { ...state };
  }
};

export default eventsReducer;
