import axios from 'axios';
import { accessToken } from '../..';
import { GATEWAY_API_URL } from '../../constants/config';

const client = axios.create({
  baseURL: GATEWAY_API_URL,
});

client.interceptors.request.use((config) => {
  config.headers.put['Content-Type'] = 'text/plain;charset=UTF-8';

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
});

const request = function (options) {
  const onSuccess = function (response) {
    // console.debug('Request Successful!', response);
    return response.data;
  };

  const onError = function (error) {
    // console.error('Request Failed:', error.config);

    if (error.response) {
      // console.error('Status:', error.response.status);
      // console.error('Data:', error.response.data);
      // console.error('Headers:', error.response.headers);
    } else {
      // console.error('Error Message:', error.message);
    }

    throw new Error(error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
