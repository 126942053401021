import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@dechea/orc.services.library';

import { getPatientName } from '../../../../utils/getPatientName';
import GridContainer from '../../../Mui/GridContainer/GridContainer';
import GridItem from '../../../Mui/GridItem/GridItem';
import PatientAvatar from '../../../PatientAvatar';
import RightArrow from './RightArrow';
import { useStyles } from './styles';
import { history } from '../../../..';

const PatientProfile = ({ patient }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const redirectToPatientPage = () => history.push(`/patients/${patient?.id}`);

  return (
    <GridContainer
      alignItems="flex-start"
      justify="space-between"
      data-testid="patientProfile"
      wrap="nowrap"
    >
      <GridItem item={false}>
        <GridContainer alignItems="center" wrap="nowrap">
          <PatientAvatar patient={patient} className={styles.patientImg} />
          <div className={styles.patientNameWrapper}>
            <Typography className={styles.patientLabel}>
              {t('calendar.eventDetail.patient')}
            </Typography>
            <Typography
              className={styles.patientName}
              data-testid="patientName"
            >
              {getPatientName(patient)}
            </Typography>
          </div>
        </GridContainer>
      </GridItem>
      <GridItem item={false}>
        <GridContainer
          onClick={redirectToPatientPage}
          alignItems="center"
          className={styles.patientFileButton}
          wrap="nowrap"
          data-testid="patientFileButton"
        >
          <Typography className={styles.patientFileButtonTitle}>
            {t('calendar.eventDetail.patientFile')}
          </Typography>
          <RightArrow />
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

PatientProfile.propTypes = {
  patient: PropTypes.object,
};

export default PatientProfile;
