import React from 'react';

const RightArrow = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.15804 7.87413L6.26065 4.97662C6.18087 4.89703 6.13699 4.79062 6.13699 4.67715C6.13699 4.56362 6.18087 4.45727 6.26065 4.37755L6.51454 4.12379C6.59419 4.04395 6.70067 4 6.81414 4C6.9276 4 7.03396 4.04395 7.11367 4.12379L10.5635 7.57353C10.6435 7.6535 10.6873 7.76035 10.687 7.87394C10.6873 7.98804 10.6436 8.09477 10.5635 8.1748L7.11688 11.6212C7.03717 11.701 6.93082 11.745 6.81729 11.745C6.70382 11.745 6.59747 11.701 6.51769 11.6212L6.26386 11.3674C6.0987 11.2023 6.0987 10.9334 6.26386 10.7683L9.15804 7.87413Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RightArrow;
