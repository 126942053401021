import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import useBlurHandler from '../../../../hooks/useBlurHandler';

import '../../stylesheets/dropdown.css';
import '../../stylesheets/checkbox.css';
import '../../stylesheets/radio.css';
import DropdownHeader from '../DropdownHeader/DropdownHeader';
import DropdownBody from '../DropdownBody/DropdownBody';

const Dropdown = ({
  options,
  selectedOptions,
  onSelectPractitioner,
  onSearch,
  searchTerm,
  onClearInputText,
  searchOptions,
  isAllOptionsSelected,
  selectedOptionsList,
  isError,
}) => {
  const [isDropdownVisible, setDropdownVisiblity] = useState(false);
  const ref = useRef(null);

  // call for mount and unmount dropdown options container.
  const toggleCalendarVisiblity = debounce(() => {
    setDropdownVisiblity(!isDropdownVisible);
  }, 100);

  // to unmount dropdown option container on mouse down
  useBlurHandler(ref, toggleCalendarVisiblity);

  return (
    <>
      <div className="dropDownRoot" data-testid="dropdownMenu">
        <DropdownHeader
          toggleCalendarVisiblity={toggleCalendarVisiblity}
          isDropdownVisible={isDropdownVisible}
          isAllOptionsSelected={isAllOptionsSelected}
          selectedOptionsList={selectedOptionsList}
          isError={isError}
        />
        {isDropdownVisible && (
          <DropdownBody
            ref={ref}
            options={options}
            selectedOptions={selectedOptions}
            onSearch={onSearch}
            searchTerm={searchTerm}
            onClearInputText={onClearInputText}
            searchOptions={searchOptions}
            onSelectPractitioner={onSelectPractitioner}
          />
        )}
      </div>
    </>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelectPractitioner: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  onClearInputText: PropTypes.func.isRequired,
  searchOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAllOptionsSelected: PropTypes.bool.isRequired,
  selectedOptionsList: PropTypes.array.isRequired,
  isError: PropTypes.bool.isRequired,
};

export default Dropdown;
