import React from 'react';

const ArrowIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.48704 9.31982L5.49413 6.1359C5.41192 6.04823 5.302 6 5.1848 6C5.06753 6 4.95768 6.04823 4.87534 6.1359L4.61322 6.41489C4.53075 6.50242 4.48535 6.61943 4.48535 6.74412C4.48535 6.86881 4.53075 6.98568 4.61322 7.07328L8.17654 10.8642C8.25914 10.9522 8.36951 11.0003 8.48685 11C8.6047 11.0003 8.71494 10.9523 8.79761 10.8642L12.3575 7.07681C12.44 6.98921 12.4854 6.87234 12.4854 6.74758C12.4854 6.62289 12.44 6.50602 12.3575 6.41835L12.0954 6.13943C11.9248 5.95793 11.647 5.95793 11.4765 6.13943L8.48704 9.31982Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowIcon;
