import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: '16px 0',
  },
  img: {
    height: 24,
    width: 24,
    borderRadius: 33,
    objectFit: 'cover',
    flexShrink: 0,
  },
  practitionerName: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '145%',
    color: theme.palette.selagoBlue,
    marginLeft: 12,
  },
}));
