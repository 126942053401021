import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import moment from 'moment';

import EventCalendar from '../../components/EventCalendar/EventCalendar';
import { setEvents } from '../../store/actions/events';
import { displayNotification } from '../../store/actions/notification';
import connect from '../../store/connect';
import { MONTH_VIEW } from '../../constants/views';
import { getCalendarActiveView } from '../../utils/manageCalendarView';
import Event from '../../components/Event/Event';
import CalendarService from '../../api/calendar';
import { eventColors } from '../../constants/eventLabelColors';
import { getEventTitle } from '../../utils/events';
import EventOverview from '../../components/EventOverview/EventOverview';

const now = new Date();

const Calendar = ({
  events,
  setEvent,
  browserLanguage,
  displayNotificationHandler,
}) => {
  const [view, setView] = useState({});
  const [tempEvent, setTempEvent] = useState(null);
  const [isEventPopupOpen, setEventPopupOpen] = useState(false);
  const [isEditEvent, setEditEvent] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [mySelectedDate, setSelectedDate] = useState(now);
  const [hasMoreEvents, setHasMoreEvents] = useState(true);
  const [eventPage, setEventPage] = useState(1);
  const [getEventStartDate, setGetEventStartDate] = useState();
  const [activeEventId, setActiveEventId] = useState(null);
  const [isEventClicked, setEventClicked] = useState(false);

  // SET CALENDAR VIEW
  useEffect(() => {
    handleChangeCalendarView(MONTH_VIEW);
  }, []);

  const onChangeCalendarViewAndDate = (isDateChange) => {
    const viewStartDate = view?.viewStartDate;
    const startDate = moment(mySelectedDate).startOf(
      view?.viewMode || MONTH_VIEW
    );
    const isSameMonth =
      moment(viewStartDate).month() === moment(mySelectedDate).month();
    const isSameYear =
      moment(viewStartDate).year() === moment(mySelectedDate).year();

    setView({
      ...view,
      viewStartDate: startDate,
    });

    if (
      isDateChange &&
      view?.viewMode === MONTH_VIEW &&
      isSameMonth &&
      isSameYear
    ) {
      return;
    }
    const formattedDate = startDate.format('YYYY-MM-DD');
    setEvent([]);
    setGetEventStartDate(formattedDate);
    setEventPage(1);
    setHasMoreEvents(true);
  };

  // TO RESET EVENTS ON CHANGE CALENDAR VIEW AND GET EVENTS
  useEffect(() => {
    if (view?.viewMode) {
      onChangeCalendarViewAndDate();
    }
  }, [view?.viewMode]);

  // TO GET EVENTS ON CHANGE SELECTED DATE
  useEffect(() => {
    if (view?.viewMode) {
      onChangeCalendarViewAndDate(true);
    }
  }, [moment(mySelectedDate).format('YYYY-MM-DD')]);

  const handleChangeCalendarView = (event) => {
    const activeView = getCalendarActiveView(event?.target?.value || event);
    setView(activeView);
  };

  const onClose = useCallback(() => {
    if (!isEditEvent) {
      // refresh the list, if add popup was canceled, to remove the temporary event
      setEvent([...events]);
    }
    setEventClicked(false);
    setEventPopupOpen(false);
    setActiveEventId(null);
  }, [isEditEvent, events]);

  // scheduler options
  const onSelectedDateChange = useCallback((event) => {
    setSelectedDate(event.date);
  });

  const onEventClick = useCallback(
    (args) => {
      setActiveEventId(args?.event?.id);
      setTempEvent({ ...args.event });
      setAnchor(args.domEvent.target);
      setEventClicked(true);
    },
    [tempEvent]
  );

  const onEventCreated = useCallback(
    (args) => {
      setEditEvent(false);
      setTempEvent(args.event);
      setAnchor(args.target);
      setEventPopupOpen(true);
    },
    [tempEvent]
  );

  const onEventUpdated = useCallback((args) => {
    // here you can update the event in your storage as well, after drag & drop or resize
    // ...
  }, []);

  //TO GET EVENT LIST
  const getEvents = () => {
    if (hasMoreEvents && view?.viewMode && getEventStartDate) {
      CalendarService.getEventsByView(
        `${view?.viewMode}s`,
        getEventStartDate,
        eventPage,
        15
      )
        .then((result) => {
          const formattedEventList = result
            ?.filter((eventList) => !eventList?.archived)
            .map((eventList) => ({
              ...eventList,
              start: new Date(eventList?.startDate),
              end: new Date(eventList?.endDate),
              title: getEventTitle(eventList),
              color: eventColors[eventList?.eventTypes?.[0]?.titleCode],
            }));

          if (result?.length) {
            setEvent([...events, ...formattedEventList]);
            setEventPage(eventPage + 1);
            setHasMoreEvents(true);
          } else {
            setHasMoreEvents(false);
          }
        })
        .catch((error) => error);
    }
  };

  useEffect(() => {
    if (hasMoreEvents) {
      getEvents();
    }
  }, [eventPage, hasMoreEvents, getEventStartDate]);

  return (
    <>
      <EventCalendar
        events={events}
        setEvents={setEvent}
        view={view}
        handleChangeCalendarView={handleChangeCalendarView}
        browserLanguage={browserLanguage}
        mySelectedDate={mySelectedDate}
        onEventClick={onEventClick}
        onEventCreated={onEventCreated}
        onEventUpdated={onEventUpdated}
        onSelectedDateChange={onSelectedDateChange}
        activeEventId={activeEventId}
        setSelectedDate={setSelectedDate}
      />
      <Event
        isEdit={isEditEvent}
        isOpen={isEventPopupOpen}
        onClose={onClose}
        anchor={anchor}
        tempEvent={tempEvent}
        displayNotification={displayNotificationHandler}
        events={events}
        setEvents={setEvent}
        setSelectedDate={setSelectedDate}
        setEventPopupOpen={setEventPopupOpen}
        view={view?.viewMode}
      />
      <EventOverview
        isEventClicked={isEventClicked}
        onClose={onClose}
        anchor={anchor}
        event={tempEvent}
        events={events}
        setEvents={setEvent}
        displayNotification={displayNotificationHandler}
        setEventClicked={setEventClicked}
        onClickEditEventButton={null}
      />
    </>
  );
};

Calendar.propTypes = {
  events: PropTypes.array.isRequired,
  setEvent: PropTypes.func.isRequired,
  browserLanguage: PropTypes.string.isRequired,
};

Calendar.defaultProps = {
  events: [],
  setEvent: noop,
};

const mapStateToProps = ({ eventsReducer }) => ({
  events: eventsReducer?.events,
});

const mapDispatchToProps = (dispatch) => ({
  setEvent: (events) => dispatch(setEvents(events)),
  displayNotificationHandler: (notification) =>
    dispatch(displayNotification(notification)),
});

const withConnect = (Component) =>
  connect(mapStateToProps, mapDispatchToProps)(Component);

export default withConnect(Calendar);
