import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@dechea/orc.services.library';

import GridContainer from '../../../Mui/GridContainer/GridContainer';
import { useStyles } from './styles';

const EventTypes = ({ eventTypes }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <GridContainer
      className={styles.root}
      alignItems="center"
      data-testid="eventTypesOverview"
    >
      {eventTypes?.map((eventType) => (
        <div className={styles.eventTypeWrapper} data-testid="eventTypeWrapper">
          <Typography
            className={styles.eventTypeLabel}
            data-testid="eventTypeLabel"
          >
            {t(`calendar.eventType.${eventType?.titleCode}`)}
          </Typography>
        </div>
      ))}
    </GridContainer>
  );
};

EventTypes.propTypes = {
  eventTypes: PropTypes.array,
};

export default EventTypes;
