import React from 'react';
import PropTypes from 'prop-types';
import {
  SegmentedGroup,
  SegmentedItem,
  CalendarPrev,
  CalendarToday,
  CalendarNext,
} from '@mobiscroll/react';
import { noop } from 'lodash';
import Box from '@material-ui/core/Box';
import { useTranslation } from '@dechea/orc.services.library';

import { DAY_VIEW, MONTH_VIEW, WEEK_VIEW } from '../../constants/views';
import './calendarNavHeader.css';
import NavCalendar from '../NavCalendar/NavCalendar';

const CalendarNavHeader = ({
  view,
  onChangeView,
  setSelectedDate,
  mySelectedDate,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box display="flex" alignItems="center" minWidth="263px"></Box>
      <div
        data-testid="calendarViewSwitcherContainer"
        className="cal-header-picker"
      >
        <SegmentedGroup value={view} onChange={onChangeView}>
          <SegmentedItem value="day">{t('calendar.day')}</SegmentedItem>
          <SegmentedItem value="week">{t('calendar.week')}</SegmentedItem>
          <SegmentedItem value="month">{t('calendar.month')}</SegmentedItem>
        </SegmentedGroup>
      </div>
      <NavCalendar setSelectedDate={setSelectedDate} date={mySelectedDate} />
      <CalendarPrev className="cal-header-prev" />
      <CalendarToday className="cal-header-today" />
      <CalendarNext className="cal-header-next" />
    </>
  );
};

CalendarNavHeader.propTypes = {
  view: PropTypes.oneOf([DAY_VIEW, WEEK_VIEW, MONTH_VIEW]).isRequired,
  onChangeView: PropTypes.func.isRequired,
  mySelectedDate: PropTypes.object.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
};

CalendarNavHeader.defaultProps = {
  view: MONTH_VIEW,
  onChangeView: noop,
};

export default CalendarNavHeader;
