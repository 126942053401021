// this component render groupwise list of options.
import React from 'react';
import PropTypes from 'prop-types';

import DefaultImage from '../../../DefaultImage/index';

const stopPropagation = (e) => e.stopPropagation();

const PractitionerList = ({
  options,
  selectedOptions,
  onSelectPractitioner,
}) => {
  return (
    <div data-testid="optionsList">
      {options.map((groupList) => {
        const isSelected = selectedOptions?.includes(groupList.id);

        const onChangeGroupOption = () => onSelectPractitioner(groupList.id);

        return (
          <div
            data-testid={`practitionerOption-${groupList.id}`}
            key={groupList.id}
            className="dropdown_options_container dropdown_group_options_container"
            onClick={onChangeGroupOption}
          >
            {groupList.avatar === null || groupList.avatar === undefined ? (
              <DefaultImage className="default-image" />
            ) : (
              <img
                src={groupList.avatar}
                alt=""
                style={{ zIndex: 1, marginLeft: 0 }}
              />
            )}
            <span style={{ width: 'calc(100% - 60px)' }}>
              {groupList.displayName}
            </span>
            <input
              id={`check-${groupList.id}`}
              type="checkbox"
              checked={isSelected}
              onChange={onChangeGroupOption}
            />
            <label
              htmlFor={`check-${groupList.id}`}
              onClick={stopPropagation}
            />
          </div>
        );
      })}
      <div className="separator" />
    </div>
  );
};

PractitionerList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelectPractitioner: PropTypes.func.isRequired,
};

export default PractitionerList;
