import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const MuiTabPanel = ({ value, index, children, ...other }) => {
  return (
    <div
      data-testid={`tabPanel-${index}`}
      role="tabpanel"
      hidden={value !== index}
      id={`md-tabpanel-${index}`}
      aria-labelledby={`md-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
};

MuiTabPanel.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node,
};

export default MuiTabPanel;
