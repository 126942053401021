import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@dechea/orc.services.library';

import DefaultImage from '../../../DefaultImage/index';

const stopPropagation = (e) => e.stopPropagation();

const SearchList = ({
  searchOptions,
  selectedOptions,
  onSelectSearchMember,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {Boolean(searchOptions.length) ? (
        searchOptions.map((option) => {
          const isSelected = selectedOptions.includes(option.id);

          const onChange = () => onSelectSearchMember(option.id);

          return (
            <div
              key={option.id}
              onClick={onChange}
              data-testid="searchListContainer"
            >
              <div className="dropdown_options_container">
                {option.avatar === null || option.avatar === undefined ? (
                  <DefaultImage className="default-image" />
                ) : (
                  <img
                    src={option.avatar}
                    alt="u"
                    style={{ zIndex: 1, marginLeft: 0 }}
                  />
                )}
                <span style={{ width: 'calc(100% - 60px)' }}>
                  {option.displayName}
                </span>
                <input
                  id={`check${option.id}`}
                  type="checkbox"
                  checked={isSelected}
                  onChange={onChange}
                />
                <label
                  htmlFor={`check${option.id}`}
                  onClick={stopPropagation}
                />
              </div>
              <div className="separator" />
            </div>
          );
        })
      ) : (
        <div
          className="dropdown_options_container"
          data-testid="noOptionSearchListContainer"
        >
          <span>{t('calendar.event.noOption')}</span>
        </div>
      )}
    </>
  );
};

SearchList.propTypes = {
  searchOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelectSearchMember: PropTypes.func.isRequired,
};

export default SearchList;
