export const eventErrors = {
  patient: true,
  practitioner: true,
  eventType: true,
};

export const eventFormValidation = (formData) => {
  const errors = { ...eventErrors };
  Object.keys(formData).forEach((formDataKey) => {
    const value = formData[formDataKey];
    if (formDataKey === 'patient') {
      errors.patient = !Boolean(value);
    }
    if (formDataKey === 'practitioner') {
      errors.practitioner = !Boolean(value?.length);
    }
    if (formDataKey === 'eventType') {
      errors.eventType = !Boolean(value?.length);
    }
  });

  return errors;
};

export const isValidEventForm = (errors) => {
  return Object.values(errors).some((err) => err);
};
