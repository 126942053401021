import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: ({ isMultiDay, bgColor }) => ({
    padding: '0 4px',
    backgroundColor: isMultiDay ? bgColor : 'none',
    '&:hover': {
      background: 'rgba(0,0,0,.1)',
      borderRadius: 4,
    },
  }),
}));
