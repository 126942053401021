import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  patientImg: {
    width: 32,
    height: 32,
    borderRadius: 50,
    flexShrink: 0,
  },
  patientNameWrapper: {
    marginLeft: 12,
  },
  patientLabel: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '145%',
    color: theme.palette.poloBlue,
  },
  patientName: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '145%',
    color: theme.palette.selagoBlue,
  },
  patientFileButton: {
    background: theme.palette.stTropaz,
    borderRadius: 20,
    padding: '0 8px 0 16px',
    width: 135,
    height: 28,
    color: theme.palette.linkWater,
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.steelBlue,
    },
  },
  patientFileButtonTitle: {
    color: 'inherit',
    fontSize: 14,
    lineHeight: '145%',
    fontWeight: 600,
    marginRight: 4,
  },
}));
