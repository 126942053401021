import { getRandomNum } from './getRandomNum';

export const getConicGradient = () =>
  `conic-gradient(from ${getRandomNum(30, 70)}deg at ${getRandomNum(
    0,
    50
  )}% ${getRandomNum(50, 100)}%, #F9CE60 -0.04deg, #36DCAA ${getRandomNum(
    0,
    100
  )}deg, #346BD1 ${getRandomNum(
    0,
    100
  )}deg, #F9CE60 359.96deg, #36DCAA 421.87deg)`;
