export const SELECT_PATIENT_STYLES = {
  control: (base, state) => {
    return {
      ...base,
      position: 'relative',
      marginLeft: '4px',
      fontFamily: 'Montserrat, sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '140%',
      color: state.isFocused ? '#F3F8FE' : '#263857',
      cursor: 'text',
      border: state.isFocused ? '1px solid #356BD1' : 'none',
      boxShadow: 'none',
      backgroundColor: '#051A3E',
      borderRadius: '8px',
      overFlow: 'none !important',
      padding: '8px 0',
      '&:hover': {
        border: state.isFocused ? '1px solid #356BD1' : 'none',
      },
    };
  },
  option: (styles, { isFocused }) => {
    return {
      ...styles,

      margin: '8px auto',
      background: 'transparent',
      color: '#E0E8F7',
      padding: '8px 12px',

      '> div > div:nth-last-child(1)': {
        color: '#869dc5',
      },

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#2E5393',
        borderRadius: 8,
        color: '#FFFFFF',

        '> div > div:nth-last-child(1)': {
          color: '#FFFFFF',
        },
      },
    };
  },
  dropdownIndicator: (defaultStyles) => {
    return {
      color: '#7E98BA',
      backgroundColor: 'red',
    };
  },
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      marginLeft: '40px',
      color: '#869DC5 !important',
    };
  },
  input: (state) => ({
    paddingLeft: '40px',
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '140%',
    color: '#F3F8FE',
    border: state.isFocused ? '1px solid #356BD1' : 'none',
    backgroundColor: '#051A3E',
    borderRadius: '8px',
    maxWidth: '10px',
  }),

  menuList: (base) => ({
    ...base,
    maxHeight: '220px',
    '::-webkit-scrollbar': {
      width: '2px',
      height: '50px',
    },
    '::-webkit-scrollbar-track': {
      opacity: 0,
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '2px',
      background: '#556E9A',
    },
  }),
  menu: (base) => ({
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    hyphens: 'auto',
    marginTop: 0,
    textAlign: 'left',
    wordWrap: 'break-word',
    color: '#DAE2EF',

    backgroundColor: '#193566',
    zIndex: 3,
  }),
  valueContainer: () => ({
    lineHeight: '22px',
    color: '#6F8BBC !important',
  }),
  container: () => ({
    backgroundColor: '#193566',
    borderRadius: '12px',
    color: '#6F8BBC !important',
    fontStyle: ' normal',
    fontWeight: ' normal',
    fontSize: '13px',
    lineHeight: '140%',
    padding: '8px',
    maxHeight: '270px',
    marginTop: '4px',
    position: 'absolute',
    zIndex: 3,
    width: '336px',
  }),
  noOptionsMessage: (base) => ({
    ...base,
    display: 'flex',
    justifyContent: 'flex-start',
  }),
};

export const DROP_DOWN_MENU_STYLE = {
  control: (base) => {
    return {
      ...base,
      border: 'none',

      fontFamily: 'Montserrat, sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '18px',
      color: '#263857',
      cursor: 'pointer',
      marginLeft: 0,
      borderBottom: 'none',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      overFlow: 'none !important',
    };
  },
  valueContainer: () => ({
    padding: 0,
    marginLeft: '9px',
  }),
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      background: isFocused ? '#1C3663' : '#142A51',
      boxShadow:
        '0 25px 70px rgba(53, 69, 98, 0.15), 0 20px 50px rgba(53, 69, 98, 0.15)',
      backdropFilter: 'blur(144px)',
      borderBottom: '1px solid #25406D',
      borderRadius: 12,
      '&:hover': {
        backgroundColor: '#314363',
        color: '#DAE2EF',
      },
    };
  },
  menu: (base) => ({
    ...base,
    width: '100%',
    borderRadius: '12px',
    hyphens: 'auto',
    marginTop: 0,
    textAlign: 'left',
    wordWrap: 'break-word',
    color: '#DAE2EF',
    left: '-15px',
    backgroundColor: '#142A51',
    zIndex: 2,
  }),
};
