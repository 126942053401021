import React from 'react';

const AvatarIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.32104 6.31775C7.32104 7.92533 8.62886 9.23315 10.2365 9.23315C11.8441 9.23315 13.1519 7.92533 13.1519 6.31775C13.1519 4.71016 11.8441 3.40234 10.2365 3.40234C8.62886 3.40234 7.32104 4.71016 7.32104 6.31775Z"
        fill="currentColor"
      />
      <path
        d="M5.59034 16.6668H14.8825C15.2673 16.6668 15.5794 16.3548 15.5794 15.9699C15.5794 13.0238 13.1825 10.627 10.2364 10.627C7.29029 10.627 4.89343 13.0238 4.89343 15.9699C4.89343 16.3548 5.20546 16.6668 5.59034 16.6668Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AvatarIcon;
