import { i18n } from '@dechea/orc.services.library';

export const getTimeCount = (startTime, endTime) => {
  const endHours = endTime?.getHours();
  const startHours = startTime?.getHours();

  const endMinutes = endTime?.getMinutes();
  const startMinutes = startTime?.getMinutes();

  const hoursDiff = endHours - startHours;
  const minutesDiff = endMinutes - startMinutes;

  const isHours = hoursDiff > 0;
  const isMinutes = minutesDiff > 0;

  const hourTrans = i18n.t('common.time.hour');
  const minTrans = i18n.t('common.time.min');

  if (isHours && isMinutes) {
    return `${hoursDiff} ${hourTrans}, ${minutesDiff} ${minTrans}`;
  }

  if (isHours && !isMinutes) {
    return `${hoursDiff} ${hourTrans}`;
  }

  if (!isHours && isMinutes) {
    return `${minutesDiff} ${minTrans}`;
  }

  if (!isHours && !isMinutes) {
    return `0 ${minTrans}`;
  }
};
