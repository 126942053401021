import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: 28,
    marginTop: 24,
  },
  clock: {
    marginRight: 4,
  },
  dateAndTime: {
    fontWeight: 500,
    lineHeight: '145%',
    fontSize: 14,
    color: theme.palette.selagoBlue,
  },
  divider: {
    border: `1px solid ${theme.palette.bayOfMany}`,
    height: 24,
    marginLeft: 50,
    marginRight: 18,
  },
  dash: {
    margin: '0 10px',
    letterSpacing: '0.05em',
  },
}));
