import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.biscay,
    borderRadius: 10,
    padding: 2,
    minHeight: 37,
    maxHeight: 37,
    color: theme.palette.paleNavyBlue,
    margin: 16,
    alignItems: 'baseline',
  },
  tabIndicator: {
    display: 'none',
  },
}));
