import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import cx from 'classnames';
import { useTranslation } from '@dechea/orc.services.library';
import moment from 'moment';

import { useStyles } from './styles';
import ArrowIcon from '../../../assets/icons/ArrowIcon';
import { getTimeCount } from '../../../utils/timeCount';

const AdvancedSetting = ({
  isAllDayEvent,
  toggleAllDayEvent,
  eventTime,
  eventDate,
}) => {
  const [isShowAdvanceSetting, setShowAdvanceSetting] = useState(false);
  const styles = useStyles();

  const { t } = useTranslation();
  const toggleAdvanceSetting = () => {
    setShowAdvanceSetting(!isShowAdvanceSetting);
  };

  return (
    <>
      {isShowAdvanceSetting && (
        <div
          className={styles.advanceSettingContainer}
          data-testid="advancedSettingContainer"
        >
          <div className={styles.allDayWrapper}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAllDayEvent}
                  onChange={toggleAllDayEvent}
                  className={styles.checkbox}
                  icon={<span className={styles.allDayIcon} />}
                  checkedIcon={
                    <span
                      className={cx(styles.checkedIcon, styles.allDayIcon)}
                    />
                  }
                />
              }
              label={t('calendar.event.allDay')}
              className={styles.allDayContainer}
              classes={{ label: styles.allDayLabel }}
              data-testid="allDayCheckbox"
            />

            <Typography className={styles.selectedTimeFont}>
              {isAllDayEvent
                ? moment(eventDate?.[1]).from(eventDate?.[0], true)
                : getTimeCount(eventTime?.[0], eventTime?.[1])}
            </Typography>
          </div>

          {/* UNCOMMENT FOR ADVANCE SETTING */}
          {/* <div className={styles.dropdownWrapper}>
            <div className={styles.divider} />
            <div className={styles.alertWrapper}>
              <DropDown
                label="Alert 15 min before"
                icon={<BellIcon />}
                actionIcon={<ArrowIcon />}
                isInacitve={false}
                options={alertOptions}
                onSelectOption={noop}
              />
            </div>
            <div className={styles.divider} />

            <div className={styles.repeatWrapper}>
              <DropDown
                label="Doest not repeat"
                icon={<RepeatIcon />}
                actionIcon={<ArrowIcon />}
                isInacitve={true}
                options={repeatOptions}
                onSelectOption={noop}
              />
            </div>
          </div> */}
        </div>
      )}

      <div
        data-testid="advancedSettingCollapse"
        className={styles.advanceSettingCollapse}
        onClick={toggleAdvanceSetting}
      >
        <Typography className={styles.advanceSetting}>
          {isShowAdvanceSetting
            ? t('calendar.event.lessOptions')
            : t('calendar.event.advancedSettings')}
        </Typography>
        <div
          className={cx(styles.advanceSettingCollapseIcon, {
            [styles.lessOptionsIcon]: isShowAdvanceSetting,
          })}
        >
          <ArrowIcon />
        </div>
      </div>
    </>
  );
};

AdvancedSetting.propTypes = {
  isAllDayEvent: PropTypes.bool.isRequired,
  toggleAllDayEvent: PropTypes.func.isRequired,
  eventTime: PropTypes.array.isRequired,
};

export default AdvancedSetting;
