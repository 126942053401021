import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@dechea/orc.services.library';

import PractitionerList from '../PractitionerList/PractitionerList';
import { useStyles } from './styles';

const PractitionerWrapper = ({ practitioners }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div data-testid="practitionerWrapper">
      <Typography className={styles.practitionerLabel}>
        {`${t('calendar.eventDetail.practitioners')}:`}
      </Typography>

      {practitioners?.map((practitioner) => (
        <PractitionerList practitioner={practitioner} />
      ))}
    </div>
  );
};

PractitionerWrapper.propTypes = {
  practitioners: PropTypes.array,
};

export default PractitionerWrapper;
