import colors from '../theme/colors';

export const eventColors = {
  PAIN: {
    inActive: {
      bgColor: colors.lavenderBlush,
      sidebarBgColor: colors.cabaretRed,
      primaryColor: colors.cadillac,
      secondaryColor: colors.tamarind,
      dotColor: colors.canCan,
    },
    active: {
      bgColor: colors.mandy,
      sidebarBgColor: colors.mandy,
      primaryColor: colors.white,
      secondaryColor: colors.white,
      dotColor: colors.white,
    },
  },
  SECOND_OPINION: {
    inActive: {
      bgColor: colors.frostedMint,
      sidebarBgColor: colors.shamrock,
      primaryColor: colors.como,
      secondaryColor: colors.bush,
      dotColor: colors.vistaBlue,
    },
    active: {
      bgColor: colors.shamrock,
      sidebarBgColor: colors.shamrock,
      primaryColor: colors.bush,
      secondaryColor: colors.bush,
      dotColor: colors.bush,
    },
  },
  MEDICAL_DISCOVERY: {
    inActive: {
      bgColor: colors.magnolia,
      sidebarBgColor: colors.purpleHeart,
      primaryColor: colors.kimberly,
      secondaryColor: colors.violet,
      dotColor: colors.kimberly,
    },
    active: {
      bgColor: colors.purpleHeart,
      sidebarBgColor: colors.purpleHeart,
      primaryColor: colors.white,
      secondaryColor: colors.white,
      dotColor: colors.white,
    },
  },
  PROPHYLAXIS: {
    inActive: {
      bgColor: colors.whitePointer,
      sidebarBgColor: colors.amethyst,
      primaryColor: colors.trendyPink,
      secondaryColor: colors.grape,
      dotColor: colors.lightWisteria,
    },
    active: {
      bgColor: colors.amethyst,
      sidebarBgColor: colors.amethyst,
      primaryColor: colors.white,
      secondaryColor: colors.white,
      dotColor: colors.white,
    },
  },
  CONSULTATION: {
    inActive: {
      bgColor: colors.earlyDawn2,
      sidebarBgColor: colors.energyYellow,
      primaryColor: colors.pesto,
      secondaryColor: colors.onion,
      dotColor: colors.sapling,
    },
    active: {
      bgColor: colors.energyYellow,
      sidebarBgColor: colors.energyYellow,
      primaryColor: colors.onion,
      secondaryColor: colors.onion,
      dotColor: colors.onion,
    },
  },
  ILLNESS: {
    inActive: {
      bgColor: '',
      sidebarBgColor: '',
      primaryColor: '',
      secondaryColor: '',
      dotColor: '',
    },
    active: {
      bgColor: '',
      sidebarBgColor: '',
      primaryColor: '',
      secondaryColor: '',
      dotColor: '',
    },
  },
  VACATION: {
    inActive: {
      bgColor: '',
      sidebarBgColor: '',
      primaryColor: '',
      secondaryColor: '',
      dotColor: '',
    },
    active: {
      bgColor: '',
      sidebarBgColor: '',
      primaryColor: '',
      secondaryColor: '',
      dotColor: '',
    },
  },
  BREAK: {
    inActive: {
      bgColor: '',
      sidebarBgColor: '',
      primaryColor: '',
      secondaryColor: '',
      dotColor: '',
    },
    active: {
      bgColor: '',
      sidebarBgColor: '',
      primaryColor: '',
      secondaryColor: '',
      dotColor: '',
    },
  },
};
