import React from 'react';
import PropTypes from 'prop-types';
import GridContainer from '../../../Mui/GridContainer/GridContainer';
import EventDateAndTime from '../EventDateAndTime/EventDateAndTime';
import PatientProfile from '../PatientProfile/PatientProfile';

import { useStyles } from './styles';

const Header = ({ event }) => {
  const styles = useStyles();

  return (
    <GridContainer
      direction="column"
      justify="space-between"
      className={styles.headerWrapper}
      wrap="nowrap"
      data-testid="eventOverviewHeader"
    >
      <PatientProfile patient={event?.patientEntity} />
      <EventDateAndTime event={event} />
    </GridContainer>
  );
};

Header.propTypes = {
  event: PropTypes.object,
};

export default Header;
