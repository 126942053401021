import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.downriver,
    borderRadius: '0 0 16px 16px',
    padding: 12,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  deleteIconContainer: {
    padding: 0,
    color: theme.palette.stTropaz + '!important',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.selagoBlue + '!important',
    },
  },
  createButton: {
    background: theme.palette.marinerBlue,
    borderRadius: 27,
    padding: '12px 24px',
    '&:hover': {
      background: theme.palette.royalBlueVariant,
    },
  },
  createButtonLabel: {
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '130%',
  },
}));
