import React from 'react';
import moment from 'moment';
import { useTranslation } from '@dechea/orc.services.library';

import { useStyles } from './styles';
import { getConcatedEventTypes } from '../../utils/getFormattedEventTypes';

const EventLabel = ({ activeEventId, ...props }) => {
  const colors = {
    color:
      activeEventId === props?.id
        ? props?.original?.color?.active
        : props?.original?.color?.inActive,
  };

  const styles = useStyles({
    ...colors?.color,
    isMultiDay: props.isMultiDay,
  });
  const { t } = useTranslation();

  const isNewEvent = props?.id?.toString()?.includes?.('mbsc');

  const renderNewEvent = () => (
    <div data-testid="newEventContainer" className={styles.newEventContainer}>
      <div data-testid="newEventLabel" className={styles.newEventTitle}>
        {t('calendar.newEvent')}
      </div>
    </div>
  );

  const renderCreatedEvent = () => (
    <div
      data-testid="createdEventLabelContainer"
      className={styles.singleDayRoot}
    >
      <div
        data-testid="eventDotTitleWrapper"
        className={styles.dotTitleWrapper}
      >
        <div data-testid="eventDot" className={styles.singleEventDot} />
        <div data-testid="eventLabelTitle" className={styles.eventTitle}>
          {getConcatedEventTypes(props?.original?.eventTypes)}
        </div>
      </div>
      <div data-testid="eventTime" className={styles.eventTime}>
        {moment(props?.original?.start).format('HH:mm')}
      </div>
    </div>
  );

  return (
    <div data-testid="eventLabelContainer">
      {isNewEvent ? renderNewEvent() : renderCreatedEvent()}
    </div>
  );
};

export default EventLabel;
