import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  singleDayRoot: (props) => ({
    display: 'flex',
    alignItems: 'center',
    height: 24,
    backgroundColor: props.isMultiDay ? props?.bgColor : 'transparent',
    borderRadius: '0 4px 4px 0',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: props.isMultiDay ? 'transparent' : 'inherit',
    },
  }),
  singleEventDot: (props) => ({
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: props.sidebarBgColor,
    flexShrink: 0,
  }),
  eventTitle: {
    paddingLeft: 6,
    color: theme.palette.rhinoBlue,
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '130%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  eventTime: {
    color: theme.palette.waikawaGray,
    fontFamily: 'Montserrat',
    fontSize: 10,
    fontWeight: 500,
    lineHeight: '140%',
    textAlign: 'right',
    letterSpacing: '0.05em',
  },
  newEventContainer: {
    backgroundColor: theme.palette.shipCove,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    height: 24,
  },
  newEventTitle: {
    paddingLeft: 14,
    color: theme.palette.common.white,
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '130%',
  },
  dotTitleWrapper: {
    display: 'flex',
    width: 'calc(100% - 40px)',
    alignItems: 'center',
  },
}));
